// Webpack Imports
import * as bootstrap from 'bootstrap';

////Export CSV users and jobs data 




////Export CSV users and jobs data end




// Function to update URL with latitude and longitude parameters
function updateUrlWithCoordinates(latitude, longitude) {
	// Get the current URL
	var currentUrl = window.location.href;

	// Check if the URL already contains latitude and longitude parameters
	var regex = /[?&]latitude=([^&#]*)/g;
	var latitudeMatches = regex.exec(currentUrl);
	regex = /[?&]longitude=([^&#]*)/g;
	var longitudeMatches = regex.exec(currentUrl);

	// If the parameters exist, replace their values
	if (latitudeMatches && latitudeMatches.length > 1 && longitudeMatches && longitudeMatches.length > 1) {
		var newUrl = currentUrl.replace(/(latitude=)[^&]+/, '$1' + latitude);
		newUrl = newUrl.replace(/(longitude=)[^&]+/, '$1' + longitude);
		window.history.replaceState({}, '', newUrl);
	} else {
		// If the parameters do not exist, add them to the URL
		var separator = currentUrl.indexOf('?') !== -1 ? '&' : '?';
		var newUrl = currentUrl + separator + 'latitude=' + latitude + '&longitude=' + longitude;
		window.history.replaceState({}, '', newUrl);
	}
}

	

// Get the #edit-details button
var editButton = document.querySelector("#edit-details");

// Get the #form-container-contact-details container
var formContainer = document.querySelector("#form-container-contact-details");

// Get the #container-details container
var detailsContainer = document.querySelector("#container-details");

// Check if all elements have been successfully retrieved
if (editButton && formContainer && detailsContainer) {
	// Add click event handling to the #edit-details button
	editButton.addEventListener("click", function() {
		// Show the #form-container-contact-details container
		formContainer.style.display = "block";
		
		// Hide the #container-details container
		detailsContainer.style.display = "none";
	});
}


// from footer start


var meineStellenangeboteElement = document.getElementById('meine-stellenangebote');

// If the element exists, add an event listener
if (meineStellenangeboteElement !== null) {
	meineStellenangeboteElement.addEventListener('click', function (event) {
		// Prevent the default action of the link
		event.preventDefault();

		// Redirect to /my-account/#meine-stellenangebote-tab-pane/#pending-pane
		var newURL = '/my-account/#meine-stellenangebote-tab-pane/#pending-pane';
		window.location.href = newURL;

		location.reload(); // Refresh the page
	});
}


// Check if /#pending-pane is present in the URL and perform appropriate actions
if (window.location.href.includes('/#pending-pane')) {
	var pendingTab = document.getElementById('pending-tab');

	// Create a new badge element
	var newBadge = document.createElement('span');
	newBadge.classList.add('badge', 'bg-secondary');
	newBadge.textContent = 'New';

	// Add the new badge to the #pending-tab tab
	pendingTab.appendChild(newBadge);

	// Set a timer to remove the badge after 3 minutes
	setTimeout(function () {
		pendingTab.removeChild(newBadge);
	}, 180000); // 3 minutes in milliseconds

	// Remove the "active" class from all tabs
	var tabs = document.querySelectorAll('#myTab .nav-link');
	tabs.forEach(function (tab) {
		tab.classList.remove('active');
	});

	// Add the "active" class only to the "Meine Stellenangebote" tab
	var meineStellenangeboteTab = document.getElementById('meine-stellenangebote-tab');
	meineStellenangeboteTab.click();

	// meineStellenangeboteTab.classList.add('active');

	var meineStellenangeboteTabPane = document.getElementById('meine-stellenangebote-tab-pane');
	meineStellenangeboteTabPane.classList.add('active');
	meineStellenangeboteTabPane.classList.add('show');

	// Activate the #pending-tab tab
	var myTabs = new bootstrap.Tab(document.querySelector('#pending-tab'));
	myTabs.show();
}


// Check if the element with the id "stellenangebot-erstellen" exists on the page
var stellenangebotErstellenElement = document.getElementById('stellenangebot-erstellen');

// If the element exists, add an event listener
if (stellenangebotErstellenElement !== null) {
	stellenangebotErstellenElement.addEventListener('click', function(event) {
		// Prevent the default action of the link
		event.preventDefault();

		// Redirect to /my-account/#stellenangebot-erstellen-tab-pane
		window.location.href = '/my-account/#stellenangebot-erstellen-tab-pane';
		location.reload(); // Refresh the page
	});
}


// async function fetchAndProcessJobs() {
// 	// Pobierz wartości lokalizacji z URL
// 	const urlParams = new URLSearchParams(window.location.search);
// 	const userLatitude = parseFloat(urlParams.get('latitude'));
// 	const userLongitude = parseFloat(urlParams.get('longitude'));
// 	const selectedRadius = parseInt(urlParams.get('distance')) || 20000; // Promień w metrach

// 	// Sprawdź, czy wartości lokalizacji są poprawne
// 	if (isNaN(userLatitude) || isNaN(userLongitude)) {
// 		console.error("Invalid latitude or longitude in URL");
// 		return [];
// 	}

// 	try {
// 		// Pobierz posty typu jobs z API
// 		const response = await fetch('/wp-json/wp/v2/jobs?per_page=100&acf');
// 		const posts = await response.json();

// 		// Filtruj posty na podstawie odległości
// 		const validTerms = posts.filter(post => {
// 			if (post.acf) {
// 				const postLatitude = parseFloat(post.acf.latitude);
// 				const postLongitude = parseFloat(post.acf.longitude);
// 				const distance = getDistance(userLatitude, userLongitude, postLatitude, postLongitude);

// 				return distance <= selectedRadius; // Promień w metrach
// 			}
// 			return false;
// 		});

// 		return validTerms;

// 	} catch (error) {
// 		console.error("Error fetching posts:", error);
// 		return [];
// 	}
// }
// Funkcja do obliczania odległości w metrach między dwoma punktami (wzór Haversine)




function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371000; // Promień Ziemi w metrach
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Odległość w metrach
}

// Funkcja do pobierania i filtrowania postów
async function fetchAndProcessJobs() {
    // Pobierz wartości lokalizacji z URL
    const urlParams = new URLSearchParams(window.location.search);
    const userLatitude = parseFloat(urlParams.get('latitude'));
    const userLongitude = parseFloat(urlParams.get('longitude'));
    const selectedRadius = parseInt(urlParams.get('distance')) || 20000; // Promień w metrach

    // Sprawdź, czy wartości lokalizacji są poprawne
    if (isNaN(userLatitude) || isNaN(userLongitude)) {
        console.error("Invalid latitude or longitude in URL");
        return [];
    }

    try {
        // Pobierz posty typu jobs z API
        const response = await fetch('/wp-json/wp/v2/jobs?per_page=100&acf');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const posts = await response.json();

        // Filtruj posty na podstawie odległości
        const validTerms = posts.filter(post => {
            if (post.acf) {
                const postLatitude = parseFloat(post.acf.latitude);
                const postLongitude = parseFloat(post.acf.longitude);

                // Sprawdź, czy wartości lokalizacji są poprawne
                if (isNaN(postLatitude) || isNaN(postLongitude)) {
                    console.error("Invalid latitude or longitude in post:", post);
                    return false;
                }

                const distance = getDistance(userLatitude, userLongitude, postLatitude, postLongitude);

                return distance <= selectedRadius; // Promień w metrach
            }
            return false;
        });

        // Wyświetl przefiltrowane posty w konsoli
        console.log(validTerms);
        return validTerms;

    } catch (error) {
        console.error("Error fetching posts:", error);
        return [];
    }
}

// Wywołaj funkcję, gdy strona jest gotowa
document.addEventListener('DOMContentLoaded', fetchAndProcessJobs);

// Funkcja do aktualizacji wyników na podstawie zmiany URL
function updateResultsOnUrlChange() {
    fetchAndProcessJobs().then(validTerms => {
        // Możesz zaktualizować widok strony na podstawie validTerms tutaj
        // Na przykład, możesz wyświetlić wyniki w HTML
    });
}

// Nasłuchiwanie na zmiany w URL
window.addEventListener('popstate', () => {
    updateResultsOnUrlChange();
});

// Wywołanie funkcji przy początkowym załadowaniu strony
document.addEventListener('DOMContentLoaded', () => {
    updateResultsOnUrlChange();
});


$(document).on('click', '#js-filtering-home', function(e) {
	e.preventDefault();

	$('#exampleModalFilters').modal('hide');
	$('#js-filtering-home').addClass('loading-btn');
	$('.spinner-wrap').addClass('loading');

	const locationInput = document.querySelector('#locations');
	const latitude = parseFloat(locationInput.getAttribute('data-latitude'));
	const longitude = parseFloat(locationInput.getAttribute('data-longitude'));
	const radius = $('#radius').val();

	if (!radius || radius === "0") {
		handleAjaxRequest([], [locationInput.value]);
	} else if (latitude && longitude) {
		fetchAndProcessJobs(latitude, longitude).then(validTerms => {
			const validTermIds = validTerms.map(term => term.id);
			const validTermNames = validTerms.map(term => term.name);

			handleAjaxRequest(validTermIds, validTermNames);
		});
	} else {
		handleAjaxRequest();
	}

	function sanitizeInput(input) {
		if (typeof input !== 'undefined' && input !== null) {
			const specialCharacters = {
				'Ãƒâ€žÃ¢â‚¬Â¦': 'a',
				'Ãƒâ€žÃ¢â‚¬Â¡': 'c',
			};

			const sanitizedInput = input.replace(/[^\w\s]/gi, (match) => {
				return specialCharacters[match] || '';
			});

			return sanitizedInput;
		} else {
			return '';
		}
	}

	function handleAjaxRequest(validTermIds = [], validTermNames = []) {
		const locations = $('#locations').val();
		const teams = $('#teams').val();
		const types = $('#types').val();
		const min_salary = $("#min_salary").val();
		const max_salary = $("#max_salary").val();
		const input_search = $('#input_search').val();
		const radius = $('#radius').val();
		const homeoffice = $('#homeoffice').val();
		const unique_params = $('unique_param').val();

		let distanceResults;

		if (!radius || radius === "0" || validTermNames.length === 0) {
			distanceResults = locations; 
		} else {
			console.log('Before assignment:', validTermNames);
			distanceResults = validTermNames.join(',');
			console.log('After assignment:', distanceResults);
		}

		const params = {
			locations: locations,
			teams: teams,
			types: types,
			min_salary: min_salary,
			max_salary: max_salary,
			input_search: input_search,
			distanceResults: distanceResults,
			distance: radius,
			homeoffice: homeoffice,
			latitude: latitude,
			longitude: longitude,
			unique_params: unique_params,
		};
		
		const queryString = $.param(params);
		const url = `/jobs/?${queryString}`;
		const ajax_url = '/wp-admin/admin-ajax.php';

		$.ajax({
			type: 'GET',
			url: ajax_url,
			dataType: 'html',
			data: {
				'action': 'filter_projects',
				'locations': locations,
				'teams': teams,
				'types': types,
				'min_salary': min_salary,
				'max_salary': max_salary,
				'input_search': input_search,
				'distance-results': validTermNames.join(','),
				'distance': radius,
				'homeoffice': homeoffice,
				'unique_params': unique_params,
			},
			success: function(res) {
				$('.preloader').addClass('hidden');
				$('#js-filtering-home').removeClass('loading-btn');
				$('.spinner-wrap').removeClass('loading');
		
				window.history.pushState({}, '', url);
		
				$('#exampleModalFilters').modal('hide');
				location.reload();
			},
			error: function() {
				$('.preloader').addClass('hidden');
				$('#js-filtering-home').removeClass('loading-btn');
				$('.spinner-wrap').removeClass('loading');
		
				$('.jobListing').html('<div class="productWrapper noOfferts"><div class="productCarousel"><div class="container"><div class="row"><h1 class="text-center">Unfortunately, we have no suitable offers</h1></div></div></div></div>');
				$('#load_using_ajax').hide();
				$('.sectionJobOffers__details').show();
				$('#job-number').show(); // Show the element with the number of job offers
				$('#js-job-number').text('0'); // Set the value to 0
				$('#exampleModalFilters').modal('hide');
				alert('0');
				location.reload();
			}
		});
			
	}
	
});



// jQuery(document).ready(function ($) {

	$(document).ready(function(){
		$('#js-close').click(function(){
			$('#js-pop-up').fadeOut('slow');
			$('#js-pop-up-on').delay(500).slideDown('slow');
		});

		$('#js-pop-up-on').click(function(){
			$('#js-pop-up').fadeIn('slow');
			$(this).slideUp('slow');
		});
	});

	
	$(document).ready(function(){
		// When clicking on an element 'a' with the class 'acf-icon-cancel.dark'
		$('#change-profile-picture a').click(function(event){
			event.preventDefault(); // Prevent the default behavior of the link
			
			$('#profile-btn').show();
		});
	
		$('#change-profile-picture #file-upload-button').click(function(event){
			event.preventDefault(); // Prevent the default behavior of the link
			
			$('#profile-btn').show();
		});
	
		// When the file input changes
		$('input[type="file"]').change(function(){
			if($(this).val() != ""){
				// If the file field is not empty, show the profile button
				$('#profile-btn').show();
			} else {
				// If the file field is empty, hide the profile button
				$('#profile-btn').hide();
			}
		});
	
		// When clicking on the element with the id 'profile-btn'
		$('#profile-btn').click(function(){   
			$(this).hide();
		});
	});
	

	$('#submitJob').on('submit', function (event) {
		event.preventDefault();

		// Serialize the form data
		var formData = $(this).serialize();

		// Make an asynchronous request to ACF Extended
		$.ajax({
			type: 'POST',
			url: acf.ajaxurl,
			data: formData + '&action=acf_form_submit', // adjust action based on ACF Extended
			success: function (response) {
				// Handle success
				simulateSubmitSuccess();
			},
			error: function (error) {
				// Handle error
				console.error('Error:', error);
			}
		});
	});




	$(document).ready(function() {
		const targetField = $('.acf-field-65d86b3edd702');
		const selectElement = $('.acf-field-65cb2ba4e77c5 select');
		const message = $('<p class="warning-message" style="color: red; margin-top: 20px;">Bitte laden Sie Ihre Ausbildungsunterlagen hoch.</p>');
	
		// Function to check the selected value
		function checkSelectedValue() {
			const selectedText = selectElement.val();
			if (selectedText && selectedText.includes('Ehrenschrauber')) {
				targetField.addClass('is-required');
				$('label[for="acf-field_65d86b3edd702"]').html('Dokumente <div class="tooltip"><svg id="originalSVG" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0963 8.65527C11.0963 8.60527 11.0563 8.56527 11.0063 8.56527H9.74631C9.67631 8.56527 9.63631 8.60527 9.63631 8.67527V13.3353C9.63631 13.3953 9.67631 13.4353 9.73631 13.4353H10.9963C11.0663 13.4353 11.0963 13.3953 11.0963 13.3353V8.65527ZM9.46631 7.03527C9.46631 7.57527 9.83631 7.93527 10.3663 7.93527C10.9063 7.93527 11.2563 7.57527 11.2563 7.03527C11.2563 6.50527 10.9063 6.15527 10.3663 6.15527C9.83631 6.15527 9.46631 6.50527 9.46631 7.03527Z" fill="#1958d2"></path><circle cx="10.3608" cy="9.79492" r="8.87891" stroke="#1958d2"></circle></svg><span class="tooltiptext">Bitte lade deinen Gesellenbrief oder deine Prüfungsergebisse hoch.</span></div> <span class="acf-required"><span style="color: #29AD9B;">*</span></span>');
				$('.acf-field-65cb2ba4e77c5').append(message);
			} else { 
				targetField.removeClass('is-required');
				$('label[for="acf-field_65d86b3edd702"]').html('Dokumente <div class="tooltip"><svg id="originalSVG" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0963 8.65527C11.0963 8.60527 11.0563 8.56527 11.0063 8.56527H9.74631C9.67631 8.56527 9.63631 8.60527 9.63631 8.67527V13.3353C9.63631 13.3953 9.67631 13.4353 9.73631 13.4353H10.9963C11.0663 13.4353 11.0963 13.3953 11.0963 13.3353V8.65527ZM9.46631 7.03527C9.46631 7.57527 9.83631 7.93527 10.3663 7.93527C10.9063 7.93527 11.2563 7.57527 11.2563 7.03527C11.2563 6.50527 10.9063 6.15527 10.3663 6.15527C9.83631 6.15527 9.46631 6.50527 9.46631 7.03527Z" fill="#1958d2"></path><circle cx="10.3608" cy="9.79492" r="8.87891" stroke="#1958d2"></circle></svg><span class="tooltiptext">Bitte lade deinen Gesellenbrief oder deine Prüfungsergebisse hoch.</span></div>');
				$('.warning-message').remove(); // Remove the message if not required
			}
		}
	
	
		// Observing changes in the file field
		$('.acf-field-65d86b3edd702 input[type="file"]').on('change', function() {
			// Check if the user has selected a file
			if ($(this).val()) {
				// If a file is selected, hide the message
				$('.warning-message').hide();
			} else {
				// If no file is selected, show the message
				$('.warning-message').show();
			}
		});
	
		// Function to set value based on URL
		function setSelectValueFromURL() {
			const url = window.location.href;
			if (url.includes('/registrierung/')) {
				if (url.includes('/#social-media')) {
					selectElement.val('Social Media').trigger('change');
				} else if (url.includes('/#schrauberblog')) {
					selectElement.val('Schrauberblog').trigger('change');
				} else if (url.includes('/#ehrenschrauber')) {
					selectElement.val('Ehrenschrauber').trigger('change');
					$('label[for="acf-field_65d86b3edd702"]').html('Dokumente <span class="acf-required"><span style="color: #29AD9B;">*</span></span>');
					$('.acf-field-65cb2ba4e77c5').append(message);
				} else if (url.includes('/#ad-in-trade-journal')) {
					selectElement.val('Ad in Trade Journal').trigger('change');
				}
			}
		}
	
		// Calling functions on start
		checkSelectedValue();
		setSelectValueFromURL();
	
		// Handling change event on select element
		selectElement.on('change', function() {
			checkSelectedValue();
		});
	});
	
	$(document).ready(function() {
		// Function to check if documents are already added
		function checkIfDocumentsAdded() {
			let documentsAdded = false;
	
			// Iterate through each row in the repeater field
			$('.acf-field-65d86b3edd702 .acf-row').each(function() {
				// Check if the row contains an element with the class .has-value
				const hasValue = $(this).find('.acf-file-uploader').hasClass('has-value');
				// If the row contains an element with the class .has-value, set the flag to true
				if (hasValue) {
					documentsAdded = true;
					return false; // Break the loop when we find an attached document
				}
			});
	
			// Check the flag and show/hide the message
			if (documentsAdded) {
				$('.warning-message').hide();
			} else {
				$('.warning-message').show();
			}
		}
	
		// Invocation of the function to check if documents are added after page load
		checkIfDocumentsAdded();

		document.addEventListener('DOMContentLoaded', function() {
			const observer = new MutationObserver(function(mutations) {
				mutations.forEach(function(mutation) {
					if (mutation.type === 'childList' && mutation.target.classList.contains('acf-row')) {
						// If there is a change in the repeater row, recheck if documents are added
						checkIfDocumentsAdded();
					}
				});
			});

			// Mutation observer configuration
			const config = { childList: true, subtree: true };

			// Start observing mutations for the repeater container
			const container = document.querySelector('.acf-field-65d86b3edd702 .acf-input tbody');
			if (container) {
				observer.observe(container, config);
			} else {
				console.error('Cannot find container to observe mutations.');
			}
		});


});


window.onload = function() {
	// Check if the current URL contains "/#edit-job"
	if (window.location.hash === "#edit-job") {
		// Delay the script execution by 500ms to ensure all elements are ready
		setTimeout(function() {
			// Scroll to the location of the edit-job div
			var editJobDiv = document.getElementById('edit-job');
			if (editJobDiv) {
				editJobDiv.style.display = 'block';
				editJobDiv.scrollIntoView({ behavior: 'smooth' });
			}
		}, 500);
	}
};




// Check if the element with the id "register-user" exists on the page
var registerUserForm = document.getElementById('register-user');

// If the form exists, add an event listener
if (registerUserForm !== null) {
	registerUserForm.addEventListener('submit', function(event) {
		var password1 = document.getElementById('acf-field_6368eedbd95f2').value;
		var password2 = document.getElementById('acf-field_6388864d419bc').value;

		var password1Error = createErrorMessageElement('password1-error', 'Passwörter stimmen nicht überein');
		var password2Error = createErrorMessageElement('password2-error', 'Passwörter stimmen nicht überein');

		if (password1 !== password2) {
			event.preventDefault(); // Prevent form submission
			document.getElementById('acf-field_6368eedbd95f2').parentNode.appendChild(password1Error);
			document.getElementById('acf-field_6388864d419bc').parentNode.appendChild(password2Error);
		}
	});
}


// Check if the element with the id "register-user-applicant" exists on the page
var registerUserForm2 = document.getElementById('register-user-applicant');

// If the form exists, add an event listener
if (registerUserForm2 !== null) {
	registerUserForm2.addEventListener('submit', function(event) {
		var password1 = document.getElementById('acf-field_65cb2b6131689').value;
		var password2 = document.getElementById('acf-field_65cb2b6f3168a').value;

		var password1Error = createErrorMessageElement('password1-error', 'Passwörter stimmen nicht überein');
		var password2Error = createErrorMessageElement('password2-error', 'Passwörter stimmen nicht überein');

		if (password1 !== password2) {
			event.preventDefault(); // Prevent form submission
			document.getElementById('acf-field_65cb2b6131689').parentNode.appendChild(password1Error);
			document.getElementById('acf-field_65cb2b6f3168a').parentNode.appendChild(password2Error);
		}
	});
}


// function createErrorMessageElement(id, message) {
// 	var errorMessage = document.createElement('div');
// 	errorMessage.id = id;
// 	errorMessage.className = 'error-message';
// 	errorMessage.textContent = message;
// 	return errorMessage;
// }

// // Check if the element with the id "aktualisieren" exists on the page
// var aktualisierenButton = document.getElementById('aktualisieren');

// // If the button exists, add an event listener
// if (aktualisierenButton !== null) {
// 	aktualisierenButton.addEventListener('click', function(event) {
// 		event.preventDefault(); // Prevent the default redirection action

// 		// Remove existing error messages
// 		var errorMessages = document.querySelectorAll('.error-message');
// 		errorMessages.forEach(function(element) {
// 			element.remove();
// 		});

// 		// Check if both mandatory checkboxes are checked
// 		var datenschutzerklarungenCheckbox = document.querySelector('input[name="acf[field_65524e164ad90][]"]');
// 		var additionalInfoCheckbox = document.querySelector('input[name="acf[field_65c3624a0aa4b][]"]');

// 		if (!datenschutzerklarungenCheckbox.checked || !additionalInfoCheckbox.checked) {
// 			// Display error message below checkboxes
// 			if ((!datenschutzerklarungenCheckbox.checked || datenschutzerklarungenCheckbox.disabled) && !datenschutzerklarungenCheckbox.hasAttribute('disabled')) {
// 				var errorMessage1 = document.createElement('p');
// 				errorMessage1.classList.add('error-message');
// 				errorMessage1.textContent = "The 'Data protection declarations and the correctness of my data' field is required.";
// 				datenschutzerklarungenCheckbox.parentNode.appendChild(errorMessage1);
// 			}
// 			if ((!additionalInfoCheckbox.checked || additionalInfoCheckbox.disabled) && !additionalInfoCheckbox.hasAttribute('disabled')) {
// 				var errorMessage2 = document.createElement('p');
// 				errorMessage2.classList.add('error-message');
// 				errorMessage2.textContent = "The 'When saving your changes, the ad will be deactivated automatically and checked by Schrauberjobs' field is required.";
// 				additionalInfoCheckbox.parentNode.appendChild(errorMessage2);
// 			}
// 			// Stop further form processing
// 			return false;
// 		}

// 		// If both fields are checked, submit the form
// 		var form = document.getElementById('edit-jobs');
// 		var currentUrl = window.location.href;
// 		var updatedUrl = currentUrl.replace('#edit-job', '');
// 		window.history.replaceState(null, null, updatedUrl);

// 		// Remove disabled attribute from checkboxes before submitting the form
// 		datenschutzerklarungenCheckbox.disabled = false;
// 		additionalInfoCheckbox.disabled = false;

// 		form.submit();
// 	});
// }

// document.addEventListener('DOMContentLoaded', function() {
//     var editDetailsButton = document.querySelector('#edit-details');

//     // Obsługa kliknięcia w przycisk #edit-details
//     editDetailsButton.addEventListener('click', function() {
//         var datenschutzerklarungenCheckbox = document.querySelector('#update-applicant-user input[name="acf[field_6618006b0641f][]"]');
//         var additionalInfoCheckbox = document.querySelector('#update-applicant-user input[name="acf[field_661800370641e][]"]');

//         // Funkcja do dodawania atrybutu disabled, jeśli atrybut checked jest ustawiony
//         function disableIfChecked(checkbox) {
//             if (checkbox.checked) {
//                 checkbox.disabled = true;
//             }
//         }

//         // Sprawdzenie, czy checkboxy zostały znalezione
//         if (datenschutzerklarungenCheckbox && additionalInfoCheckbox) {
//             // Sprawdzenie i dodanie atrybutu disabled dla obu checkboxów
//             disableIfChecked(datenschutzerklarungenCheckbox);
//             disableIfChecked(additionalInfoCheckbox);
//         }
//     });
// });


jQuery(document).ready(function($) {
    // Check if the aktualisieren button exists on the page
    var aktualisierenButton = $('#aktualisieren');

    // If the button exists, add an event listener
    if (aktualisierenButton.length) {
        aktualisierenButton.on('click', function(event) {
            event.preventDefault(); // Prevent the default redirection action

            // Remove existing error messages
            $('.error-message').remove();

            // Check if both mandatory checkboxes are checked
            var datenschutzerklarungenCheckbox = $('input[name="acf[field_65524e164ad90][]"]');
            var additionalInfoCheckbox = $('input[name="acf[field_65c3624a0aa4b][]"]');

            if (!datenschutzerklarungenCheckbox.is(':checked') || !additionalInfoCheckbox.is(':checked')) {
                // Display error message below checkboxes
                if (!datenschutzerklarungenCheckbox.is(':checked')) {
                    var errorMessage1 = $('<p>').addClass('error-message').text("The 'Data protection declarations and the correctness of my data' field is required.");
                    datenschutzerklarungenCheckbox.parent().append(errorMessage1);
                }
                if (!additionalInfoCheckbox.is(':checked')) {
                    var errorMessage2 = $('<p>').addClass('error-message').text("The 'When saving your changes, the ad will be deactivated automatically and checked by Schrauberjobs' field is required.");
                    additionalInfoCheckbox.parent().append(errorMessage2);
                }
                // Stop further form processing
                return false;
            }

            // If both fields are checked, submit the form
            var form = $('#edit-jobs');
            var currentUrl = window.location.href;
            var updatedUrl = currentUrl.replace('#edit-job', '');
            window.history.replaceState(null, null, updatedUrl);

            // Remove disabled attribute from checkboxes before submitting the form
            datenschutzerklarungenCheckbox.prop('disabled', false);
            additionalInfoCheckbox.prop('disabled', false);

            // Remove 'required' attribute from disabled checkboxes
            $('input[type="checkbox"][disabled]').removeAttr('required');

            // Submit the form
            form.submit();
        });
    }

});




jQuery(document).ready(function($) {
    // Sprawdzamy, czy znajdujemy się na stronie o klasie .page-template-page-myaccount-applicant
    if ($('body').hasClass('page-template-page-myaccount-applicant')) {
        // Obsługa kliknięcia na etykietę checkboxa wewnątrz określonego obszaru strony
       
		$('.page-template-page-myaccount-applicant .acf-field-checkbox.acf-field-661801efa4d37 label, .page-template-page-myaccount-applicant .acf-field-checkbox.acf-field-66180221a4d38 label').on('click', function(e) {
            // Zapobiegamy domyślnemu zachowaniu kliknięcia
            e.preventDefault();

            // Sprawdzamy, czy etykieta ma klasę "selected"
            if ($(this).hasClass('selected')) {
                // Zapobiegamy propagacji zdarzenia, aby uniemożliwić zmianę stanu checkboxa
                e.stopPropagation();
                // Przerywamy dalsze działanie obsługi zdarzenia
                return false;
            }
        });
    }
});



// Check if the element exists before adding event listeners
var showWorkshopButton = document.getElementById('js-show-workshop');
if (showWorkshopButton) {
	showWorkshopButton.addEventListener('click', function(event) {
		event.preventDefault();
		document.getElementById('js-register-workshop').style.display = 'block';
		document.getElementById('js-register-selection').style.display = 'none';
	});
}

var showApplicantButton = document.getElementById('js-show-applicant');
if (showApplicantButton) {
	showApplicantButton.addEventListener('click', function(event) {
		event.preventDefault();
		document.getElementById('js-register-applicant').style.display = 'block';
		document.getElementById('js-register-selection').style.display = 'none';
	});
}

document.addEventListener('DOMContentLoaded', function() {
	var reloadButton = document.getElementById('reloadButton');
	if (reloadButton) {
		reloadButton.addEventListener('click', function() {
			window.location.reload(); // This line reloads the page
		});
	}
});



window.addEventListener('load', function() {
	var path = window.location.pathname;
	var hash = window.location.hash;
	if (path.includes("/registrierung/") && hash === "#bewerber") {
	   
		document.getElementById("js-register-selection").style.display = "none"; 
		document.getElementById("js-register-workshop").style.display = "none"; 
		document.getElementById("js-register-applicant").style.display = "block"; 
	} 

	if (path.includes("/registrierung/") && hash === "#unternehmen") {
	   
	   document.getElementById("js-register-selection").style.display = "none"; 
	   document.getElementById("js-register-workshop").style.display = "none"; 
	   document.getElementById("js-register-applicant").style.display = "block"; 
   } 

});

// from footer end

document.addEventListener('DOMContentLoaded', function () {
	// Check if we are on the /jobs page and the screen width is up to 1024px
	if (window.location.pathname === '/jobs' && window.innerWidth <= 1024) {
		var mobileFilteringButton = document.getElementById('mobile-filtering');
		var filtersForm = document.querySelector('.additional-filtering'); // Use '.' for class or adjust selector as needed

		// Add touch event handling on mobile devices
		mobileFilteringButton.addEventListener('touchstart', function (event) {
			// Prevent the default touch event action (e.g., scrolling)
			event.preventDefault();

			// Check if filtersForm is not null, then toggle the 'active' class
			if (filtersForm) {
				filtersForm.classList.toggle('active');
			}
		});
	}
});





		if (typeof ym !== 'undefined') {
			ym.ready({ autocomplete: 5 }, function(modules) {

				
				var settings = {
					isoCountries: ['de', 'at'],  // Set initial value of isoCountries
					includeFilters: { city: true, zipCity: true },
					showCountry: true,
					showZip: true,
					showStreet: false,
					showPoi: false,
					language: 'de',
					onSelected: function(geoJson) {
						if (geoJson && geoJson.geometry && geoJson.geometry.coordinates) {
							var longitude = geoJson.geometry.coordinates[0];
							var latitude = geoJson.geometry.coordinates[1];
							
				
							// Update values of hidden fields
							var locationInput = document.querySelector('#locations');
							if (locationInput) {
								locationInput.setAttribute('data-latitude', latitude);
								locationInput.setAttribute('data-longitude', longitude);
							}
				
							var myaccountInput = document.querySelector('#locations');
							if (myaccountInput) {
								myaccountInput.setAttribute('data-latitude', latitude);
								myaccountInput.setAttribute('data-longitude', longitude);
							}
				
							var standortInput = document.querySelector('#acf-field_648b0da01234f');
							if (standortInput) {
								standortInput.setAttribute('data-latitude', latitude);
								standortInput.setAttribute('data-longitude', longitude);
							}

							var standortInputTest = document.querySelector('#acf-field_66ac9d0d692ee');
							if (standortInputTest) {
								standortInputTest.setAttribute('data-latitude', latitude);
								standortInputTest.setAttribute('data-longitude', longitude);
							}
				
							var latitudeInput = document.querySelector('#latitude');
							var longitudeInput = document.querySelector('#longitude');
							
				
							if (latitudeInput && longitudeInput) {
								latitudeInput.value = latitude;
								longitudeInput.value = longitude;
							}
				
							updateUrlWithCoordinates(latitude, longitude);
				
							// if (geoJson.properties && geoJson.properties.name) {
							// 	// Add selected value to Select2
							// 	var select2Element = $('#acf-field_656460515c7a2').select2();
							// 	var newOption = new Option(geoJson.properties.name, geoJson.properties.id, true, true);
							// 	select2Element.append(newOption).trigger('change');
							// }
				
							// if (geoJson.properties && geoJson.properties.name) {
							// 	isoCountries = (geoJson.properties.name === 'Deutschland') ? 'de' : 'at';
							// 	settings.isoCountries = [isoCountries];
							// }
						}
					}
				};
				
			
				// Initialize YellowMaps autocomplete for various fields
				var locationInput = document.querySelector('#locations');
				if (locationInput) {
					ym.modules.autoComplete(locationInput, settings);
				}
			
				var myaccountInput = document.querySelector('#acf-field_65575693a213a');
				if (myaccountInput) {
					ym.modules.autoComplete(myaccountInput, settings);
				}

				var myaccountInputTest = document.querySelector('#acf-field_66ac9d0d692ee');
				if (myaccountInputTest) {
					ym.modules.autoComplete(myaccountInputTest, settings);
				}
			
				var standortInput = document.querySelector('#acf-field_648b0da01234f');
				if (standortInput) {
					ym.modules.autoComplete(standortInput, settings);
				}
			
				var select2Element = document.querySelector('#acf-field_656460515c7a2');
				if (select2Element) {
					ym.modules.autoComplete(select2Element, settings);
				}

				var stadtMyaccount = document.querySelector('#acf-field_6479d7375583e-field_6479d7a155840');
				if (stadtMyaccount) {
					ym.modules.autoComplete(stadtMyaccount, settings);
				}

				var postalMyaccount = document.querySelector('#acf-field_6479d7375583e-field_6479d7525583f');
				if (postalMyaccount) {
					ym.modules.autoComplete(postalMyaccount, settings);
				}
				
			});

		} else {
			console.error('ym is not defined. Skipping YellowMaps script.');
		}




	jQuery(document).ready(function ($) {
		// Check if the field value has changed after exiting the field
		var previousValue = $("#acf-field_65cb2b6131689").val();

		$("#acf-field_65cb2b6131689").change(function() {
			var currentValue = $(this).val();
			
			if (currentValue !== previousValue) {
				// Field value has changed, send email
				// Insert code to send email here
				console.log("Sending email...");
			} else {
				// Field value has not changed, block email sending
				// Insert code to block email sending here
				console.log("Field value has not changed, blocking email sending.");
			}
		});

		$(document).ready(function() {
			$('.acf-field-637f539706f2d input').prop('disabled', true);
			$('#form-container-contact-details .acf-field-65cb2b5131688 input').prop('disabled', true);
			$('.acf-field-637f539706f2d input').prop('disabled', true);
			
		});

		$(document).ready(function(){
		
			$('#update-applicant-user').submit(function(event) {
				var fieldValue1 = $('#acf-field_65cb2b6131689').val();
				var fieldValue2 = $('#acf-field_65cb2b6f3168a').val();
				
				if (fieldValue1 !== fieldValue2) {
					$('#error-messages').text("Passwörter stimmen nicht überein.");
					event.preventDefault(); // Verhindern des Standardverhaltens des Formulars
				} else {
					$('#error-messages').text("");
				}
			});
		});

		$('#active-tab-pane').on('click', '#update-post-status-button', function(e) {
			e.preventDefault();
		
			var postID = $(this).siblings('.js-job-offer-id').val(); // Corrected to use siblings() instead of next()
			const ajax_url = '/wp-admin/admin-ajax.php';
			var data = {
				'action': 'update_post_status',
				'post_id': postID,
				'status': 'publish' // Corrected status to 'publish' for active tab
			};
		
			$.post(ajax_url, data, function(response) {
				if (response.success) {
					location.reload();
				} else {
					// Show Bootstrap modal
					$('#errorModal').modal('show');
				}
			});
		});
		
		$('#notactive-tab-pane').on('click', '#update-post-status-button', function(e) {
			e.preventDefault();
		
			var postID = $(this).siblings('.js-job-offer-id').val(); // Corrected to use siblings() instead of next()
			const ajax_url = '/wp-admin/admin-ajax.php';
			var data = {
				'action': 'update_post_status',
				'post_id': postID,
				'status': 'pending' // Corrected status to 'pending' for not active tab
			};
		
			$.post(ajax_url, data, function(response) {
				if (response.success) {
					location.reload();
				} else {
					$('#errorModal').modal('show');
				}
			});
		});
		
		$('#pending-tab-pane').on('click', '#update-post-status-button', function(e) {
			e.preventDefault();
		
			var postID = $(this).siblings('.js-job-offer-id').val(); // Corrected to use siblings() instead of next()
			const ajax_url = '/wp-admin/admin-ajax.php';
			var data = {
				'action': 'update_post_status',
				'post_id': postID,
				'status': 'publish' // Corrected status to 'publish' for pending tab
			};
		
			$.post(ajax_url, data, function(response) {
				if (response.success) {
					location.reload();
				} else {
					$('#errorModal').modal('show');
				}
			});
		});
		




		$('#trash-tab-pane #update-post-status-button').click(function(e) {
			e.preventDefault();

			var postID = $(this).next('.js-job-offer-id').val();
			// var ajaxurl = '/wp-admin/admin-ajax.php';
			const ajax_url = '/wp-admin/admin-ajax.php';
			var data = {
				'action': 'update_post_status',
				'post_id': postID,
				'status': 'draft' // The new status for the post
			};
			// Send an AJAX request to the server

			$.post(ajax_url, data, function(response) {
				if (response.success) {
					location.reload();
				} else {
					$('#errorModal').modal('show');
				}
			});
		});


		$(document).ready(function() {
			function activateTabFromHash() {
				var hash = window.location.hash;
				if (hash) {
					// Activate tab
					$('#myTab a[href="' + hash + '"]').tab('show');
		
					// Activate button
					if ($('.myaccount-icon').length) {
						$('.myaccount-menu').addClass('open');
					}
				}
			}
		
			activateTabFromHash();
		
			// Function to handle hash changes in URL
			$(window).on('hashchange', function() {
				activateTabFromHash();
			});
		
			// Handle tab clicks
			$('#myTab a').on('click', function(e) {
				e.preventDefault();
				// Synchronize the active class between the two lists
				var targetTab = $(this).attr('href');
				$('.nav-pills a[href="' + targetTab + '"]').addClass('active').parent().siblings().find('a').removeClass('active');
		
				// Show the corresponding tab-pane
				var targetPane = $(targetTab);
				targetPane.addClass('active show').siblings().removeClass('active show');
		
				// Update the URL with the active tab's href
				var tabHref = $(this).attr('href');
				history.pushState(null, null, tabHref);
		
				// Toggle .myaccount-menu
				$('.myaccount-menu').toggleClass('open');
			});
		
			// Close the .open class on clicking outside or on list item click
			$(document).on('click', function(e) {
				var target = $(e.target);
				if (!target.closest('.myaccount-menu').length) {
					$('.myaccount-menu').removeClass('open');
				}
			});
		
			// Hide .myaccount-menu on clicking any anchor link inside .myaccount-menu
			$('.myaccount-menu a').on('click', function() {
				$('.myaccount-menu').hide();
			});
		});
		
		
		$(document).ready(function() {
			// Toggle .myaccount-menu on clicking the button
			$('.myaccount-icon').on('click', function() {
				$('.myaccount-menu').toggleClass('open');
			});
		
			// Close the .open class on clicking outside or on list item click
			$(document).on('click', function(e) {
				var target = $(e.target);
				if (!target.closest('.myaccount-menu').length) {
					$('.myaccount-menu').removeClass('open');
				}
			});
		
			// Toggle .open class on list item click
			$('#myTab a').on('click', function() {
				if ($('.myaccount-menu').hasClass('open')) {
					$('.myaccount-menu').removeClass('open');
				}
			});
		
			// Toggle .myaccount-menu on clicking any anchor link inside #myTab
			$('#myTab a').on('click', function() {
				$('.myaccount-menu').toggleClass('open');
			});
		
			// Hide .myaccount-menu on clicking any .nav-link inside it
			$('.myaccount-menu .nav-link').on('click', function() {
				$('.myaccount-menu').removeClass('open');
			});
		});
		

		$(document).ready(function() {
			// Click on the account icon
			$('.myaccount-icon').click(function(event) {
				event.preventDefault();
				$('.myaccount-menu').toggle();
				$('.myaccount-menu').toggleClass('open');
				$('.myaccount-icon').toggleClass('open');
			});
		
			// Click anywhere on the document
			$(document).mousedown(function(event) {
				// Checking if the click occurred outside the menu and the account icon
				if (!$(event.target).closest('.myaccount-menu').length && !$(event.target).closest('.myaccount-icon').length) {
					// Checking if the menu is open
					if ($('.myaccount-menu').hasClass('open')) {
						// If yes, close the menu
						$('.myaccount-menu').removeClass('open'); // Remove the open class from the menu
						$('.myaccount-icon').removeClass('open'); // Remove the open class from the account icon
					}
				}
			});
		});
		


		$(document).ready(function() {
			$(".myaccount-menu").on("click", function(e) {
				e.stopPropagation(); // Prevents click event from propagating to child elements
			});
		
			$(".myaccount-menu.open").on("click", function() {
				$(".myaccount-menu.open").removeClass("open").slideUp();
			});
		});
		
});




document.addEventListener('DOMContentLoaded', function () {
	
	var formSubmitting = false;

	function handleButtonClick(event, formId) {
		event.preventDefault();

		if (!formSubmitting) {
			var clickedButton = event.target;
			showSpinner(clickedButton);
			addSendingClass(clickedButton);
			submitForm(formId, clickedButton);
		}
	}

	function addSendingClass(button) {
		button.classList.add('sending');

		setTimeout(function () {
			button.classList.remove('sending');
		}, 30000);
	}

	function showSpinner(button) {
		var acfFormSubmit = document.querySelector('.acf-form-submit');

		if (acfFormSubmit) {
			var sendingDiv = document.createElement('div');
			sendingDiv.className = 'sending';
			acfFormSubmit.appendChild(sendingDiv);
			
			setTimeout(function () {
				acfFormSubmit.removeChild(sendingDiv);
			}, 30000);
		}

		formSubmitting = true;
		setTimeout(function () {
			formSubmitting = false;
		}, 30000);
	}

	// Check if an element with the id "submission-jobs" exists on the page
	var submissionJobsForm = document.getElementById('submitJob');

	// Add event listener only if the form exists
	if (submissionJobsForm) {
		submissionJobsForm.addEventListener('submit', function(event) {
			// Prevent default form behavior (page reload)
			event.preventDefault();

			// Call the submitForm function, passing the form id and the submit input object
			submitForm('submitJob', document.getElementById('submit-job-btn'));
		});
	}

	function submitForm(formId, button) {
		var form = document.getElementById(formId);
		if (!form) {
			console.error('Form element ID not found:', formId);
			return;
		}
	
		var formData = new FormData(form);
	
		var xhr = new XMLHttpRequest();
		xhr.open('POST', form.action || '', true); // Add a safeguard for form.action
		xhr.onload = function() {
			if (xhr.status === 200) {
				// showMessage('Data updated', form);
				disableButton(button);
				simulateSubmitSuccess(); // Add a call to the simulateSubmitSuccess function
			} else {
				console.error('Error processing the request.');
			}
		};
		xhr.send(formData);

	}

	function disableButton(button) {
		// Add code to disable the button or customize as needed
		button.disabled = true;
		button.value = 'Sending...'; // Optionally change the button text
	}
	
	
	function showMessage(message, form) {
		var messageElement = document.createElement('div');
		messageElement.className = 'success-message';
		messageElement.textContent = message;
	
		// Add the message below the form where the button was clicked
		form.parentNode.insertBefore(messageElement, form.nextSibling);
	
		// Hide the message after 2 seconds
		setTimeout(function () {
			messageElement.style.display = 'none';
		}, 2000);
	}
	

	// Definition of the simulateSubmitSuccess function
	function simulateSubmitSuccess() {
		// Hide the form
		$('#form-container-contact').hide();

		// Show the success message
		$('.submission-success').show();

		// Scroll to #submission-success with an additional offset of 200px
		$('html, body').animate({
			scrollTop: $('#submission-success').offset().top - 200
		}, 1000); // You can adjust the animation duration
	} 

	var submitButton1 = document.getElementById('submit-job-btn');
	var submitButton2 = document.getElementById('acf-company-details-btn');
	var billingFormButton = document.getElementById('billing-form-btn');
	var shippingFormButton = document.getElementById('shipping-form-btn');

	if (submitButton1) {
		submitButton1.addEventListener('click', function (event) {
			handleButtonClick(event, 'submitJob');
		});
	}

	if (submitButton2) {
		submitButton2.addEventListener('click', function (event) {
			handleButtonClick(event, 'register-new-user-2');
		});
	}

	if (billingFormButton) {
		billingFormButton.addEventListener('click', function (event) {
			handleButtonClick(event, 'woocommerce-billing-form');
		});
	}

	if (shippingFormButton) {
		shippingFormButton.addEventListener('click', function (event) {
			handleButtonClick(event, 'woocommerce-shipping-form');
		});
	}
});



	jQuery(document).ready(function ($) {

		var $submitButton = $('#wpcf7-f444-o2 .wpcf7-submit');
		var $consentCheckbox = $('#wpcf7-f444-o2 input[name="your-consent"]');
		var $consentError = $('#wpcf7-f444-o2 #consent-error');

		var isCheckboxChecked = false;

		function resetCheckbox() {
			isCheckboxChecked = false;
			$consentCheckbox.prop('checked', false);
			$consentError.hide();
		}

		function handleCheckboxChange() {
			isCheckboxChecked = $consentCheckbox.is(':checked');
			if (isCheckboxChecked) {
				$consentError.hide();
				$consentError.removeClass('shown');
			} else {
				$consentError.show().css('display', 'block');
				$consentError.addClass('shown');
			}
		}

		resetCheckbox();

		$consentCheckbox.change(handleCheckboxChange);

		$submitButton.closest('form').on('submit', function (event) {
			if (!isCheckboxChecked) {
				$consentError.show().css('display', 'block');
				$consentError.addClass('shown');
				event.preventDefault();
			} else {
				resetCheckbox();
				$consentError.removeClass('shown');
			}
		});

		$submitButton.on('click', function (event) {
			if (!isCheckboxChecked) {
				$consentError.show().css('display', 'block');
				$consentError.addClass('shown');
				event.preventDefault();
			}
		});

	});


	jQuery(document).ready(function ($) {

		var $submitButton = $('#wpcf7-f1992-o1 .wpcf7-submit');
		var $consentCheckbox = $('#wpcf7-f1992-o1 input[name="your-consent"]');
		var $consentError = $('#wpcf7-f1992-o1 #consent-error');

		var isCheckboxChecked = false;

		function resetCheckbox() {
			isCheckboxChecked = false;
			$consentCheckbox.prop('checked', false);
			$consentError.hide();
			$consentError.removeClass('shown');
		}

		function handleCheckboxChange() {
			isCheckboxChecked = $consentCheckbox.is(':checked');
			if (isCheckboxChecked) {
				$consentError.hide();
				$consentError.removeClass('shown');
			} else {
				$consentError.show().css('display', 'block');
				$consentError.addClass('shown');
			}
		}

		resetCheckbox();

		$consentCheckbox.change(handleCheckboxChange);

		$submitButton.closest('form').on('submit', function (event) {
			if (!isCheckboxChecked) {
				$consentError.show().css('display', 'block');
				$consentError.addClass('shown');
				event.preventDefault();
			} else {
				resetCheckbox();
				$consentError.removeClass('shown');
			}
		});

		$submitButton.on('click', function (event) {
			if (!isCheckboxChecked) {
				$consentError.show().css('display', 'block');
				$consentError.addClass('shown');
				event.preventDefault();
			}
		});

	});


	jQuery(document).ready(function ($) {

		var $submitButton = $('#wpcf7-f2319-p2339-o2 .wpcf7-submit');
		var $consentCheckbox = $('#wpcf7-f2319-p2339-o2 input[name="your-consent"]');
		var $consentError = $('#wpcf7-f2319-p2339-o2 #consent-error');

		var isCheckboxChecked = false;

		function resetCheckbox() {
			isCheckboxChecked = false;
			$consentCheckbox.prop('checked', false);
			$consentError.hide();
		}

		function handleCheckboxChange() {
			isCheckboxChecked = $consentCheckbox.is(':checked');
			if (isCheckboxChecked) {
				$consentError.hide();
			} else {
				$consentError.show().css('display', 'block');
			}
		}

		resetCheckbox();

		$consentCheckbox.change(handleCheckboxChange);

		$submitButton.closest('form').on('submit', function (event) {
			if (!isCheckboxChecked) {
				$consentError.show().css('display', 'block');
				event.preventDefault();
			} else {
				resetCheckbox();
			}
		});

		$submitButton.on('click', function (event) {
			if (!isCheckboxChecked) {
				$consentError.show().css('display', 'block');
				event.preventDefault();
			}
		});

	});

	jQuery(document).ready(function ($) {

		var $form = $('#wpcf7-f2319-p2379-o2 form');
		var $preloader = $('#email-preloader');
		var $confirmation = $('#email-confirmation');
		var $submitButton = $form.find('.wpcf7-submit');
		var $consentCheckbox = $form.find('input[name="your-consent"]');
		var $consentError = $form.find('#consent-error');

		var isCheckboxChecked = false;

		function resetCheckbox() {
			isCheckboxChecked = false;
			$consentCheckbox.prop('checked', false);
			$consentError.hide();
		}

		function handleCheckboxChange() {
			isCheckboxChecked = $consentCheckbox.is(':checked');
			if (isCheckboxChecked) {
				$consentError.hide();
			} else {
				$consentError.show().css('display', 'block');
			}
		}

		resetCheckbox();

		$consentCheckbox.change(handleCheckboxChange);

		// Handle form submission
		$($form).on('submit', function (event) {
			var allInputsHaveValues = true;

			// Check if all text inputs have values
			$form.find('input[type="text"]').each(function () {
				if ($(this).val() === '') {
					allInputsHaveValues = false;
					return false; // Stop the loop if an empty input is found
				}
			});

			// Check if the checkbox is checked
			if (!allInputsHaveValues || !isCheckboxChecked) {
				// Display error messages if inputs are empty or checkbox is unchecked
				if (!allInputsHaveValues) {
					// Display error message indicating some inputs are empty
				}
				if (!isCheckboxChecked) {
					$consentError.show().css('display', 'block');
				}
				event.preventDefault(); // Prevent form submission
				return false;
			} else {
				// Hide the form and show the preloader
				$form.hide();
				$preloader.show();

				// Simulate delay for the preloader (3 seconds)
				setTimeout(function () {
					$preloader.hide();
					$confirmation.show();
					$('#exampleModalLabelOpinion').text('Success!');
					// $('#exampleModalLabelOpinion').html('Success!');
				}, 3000);
			}
		});

		// Change the text in the h1 element after the #email-confirmation modal is shown
		$confirmation.on('show.bs.modal', function () {
			$('#exampleModalLabelOpinion').text('Success!');
		});

	});



	document.addEventListener('wpcf7submit', function(event) {
		// Get the loader container and submit button
		var loader = document.querySelector('.custom-loader');
		var submitButton = document.querySelector('.submit-button');
	
		// Hide the submit button
		submitButton.style.display = 'none';
	
		// Display the loader
		loader.style.display = 'block';
	
		// Set a delay of 2 seconds (2000 milliseconds) and revert to the initial state
		setTimeout(function() {
			loader.style.display = 'none';
			submitButton.style.display = 'block';
		}, 2000);
	}, false);
	


	document.querySelectorAll('select').forEach(function(selectElement) {
		selectElement.addEventListener('change', function() {
			this.querySelectorAll('option').forEach(option => {
				if (option.textContent === 'Auswählen') {
					option.remove();
				}
			});
		});
	});



	function hidePreloader() {
		// Check if an element with class .preloader exists
		const preloader = document.querySelector('.preloader');
	
		if (preloader) {
			// Function to check and hide the preloader
			function checkAndHidePreloader() {
				// Get the current page URL
				const currentPageUrl = window.location.pathname;
	
				// Check if the page is "/jobs" and has parameters
				if (currentPageUrl === '/jobs' && window.location.search) {
					// Page is "/jobs" with parameters - hide the preloader
					preloader.style.display = "none";
				}
			}
	
			// Check if the preloader does not have the class .hidden
			if (!preloader.classList.contains('hidden')) {
				// Preloader does not have the .hidden class - hide it
				preloader.style.opacity = "0";
				setTimeout(function() {
					preloader.style.display = "none";
				}, 100);
			} else {
				// Preloader has the .hidden class - add an event listener for the load event
				window.addEventListener('load', checkAndHidePreloader);
			}
		}
	}
	
	// Call the hidePreloader() function after the script is loaded
	hidePreloader();
	
  

( function () {
	'use strict';

	// Focus input if Searchform is empty
	[].forEach.call( document.querySelectorAll( '.search-form' ), ( el ) => {
		el.addEventListener( 'submit', function ( e ) {
			var search = el.querySelector( 'input' );
			if ( search.value.length < 1 ) {
				e.preventDefault();
				search.focus();
			}
		} );
	} );

	// Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
	var popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
	var popoverList = popoverTriggerList.map( function ( popoverTriggerEl ) {
		return new bootstrap.Popover( popoverTriggerEl, {
			trigger: 'focus',
		} );
	} );
} )();







jQuery(document).ready(function($) {
	$("#submit-new-user").click(function() {
		$("#form_register_new_user_2").submit();
	});

	$("#submit-jobs").click(function() {
		$("#form_submission_jobs").submit();
	});
});

jQuery(function ($) {
	
	$(document).ready(function() {
		// Listening for click on button
		$('.sectionContent__jobDetails .btn-success').click(function() {
			// Short delay to ensure modal state is updated
			setTimeout(function() {
				// Checking if modal is visible
				if ($('.modalAplication').is(':visible')) {
					// Adding 'modal-open' class to body element if modal is visible
					$('body').addClass('modal-open');
				} else {
					// Removing 'modal-open' class from body element if modal is hidden
					$('body').removeClass('modal-open');
				}
			}, 100); // You can adjust the delay if needed
		});
	});
	
	$(document).ready(function(){
		$('.acf-field-637ce5199b91a .acf-input .acf-input-wrap').after('<small>z.B. Mechatroniker (m/w/d)</small>');
	});
	
	$(document).ready(function() {
		// Search through all ACF labels
		$('.acf-label').each(function() {
			// Check if the label contains a star
			if ($(this).text().indexOf('*') !== -1) {
				// Replace the star with a span with the appropriate color
				$(this).html($(this).html().replace('*', '<span style="color: #29AD9B;">*</span>'));
			}
		});
	});
	
	function isValidEmail(email) {
		// Implement your email validation logic here
		// For a simple example, we'll use a regular expression
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}
	


	$(document).ready(function () {
		$('#app-form').submit(function (e) {
	
			let isValid = true;
		
			$('.error-message').remove();
		
			$('fieldset .select-style-other').each(function() {
				const selectStyled = $(this);
				const defaultOption = 'Auswählen';
			
				// Check if the value is equal to the default value
				if (selectStyled.text().trim() === defaultOption) {
					// Remove any previous error messages
					selectStyled.siblings('.error-message').remove();
			
					// Find the label associated with the select field
					const selectLabel = selectStyled.closest('fieldset').find('label');
			
					// Add error message below the found label
					selectLabel.after('<div class="error-message">Bitte wähle eine Option aus.</div>');
					isValid = false;
				}
			
				// Listen for value change event
				selectStyled.on('change', function() {
					const selectedValue = $(this).text().trim();
			
					// If the value is not equal to the default, hide the error message
					if (selectedValue !== defaultOption) {
						$(this).siblings('.error-message').remove();
					}
				});
			});
			
			// Remove error message for input fields after text input
			$('input[required]').keyup(function () {
				if ($(this).val().trim() !== '') {
					$(this).siblings('.error-message').remove();
				}
			});
		
			// Check checkboxes
			$('input[type="checkbox"][required]').each(function () {
				if (!$(this).is(':checked')) {
					$(this).before('<div class="error-message">Dieses Feld ist erforderlich</div>');
					isValid = false;
				}
			});
		
			$('select[required]').each(function () {
				if ($(this).val() === 'Auswählen') {
					$(this).closest('div.select').before('<div class="error-message">Bitte wähle eine Option.</div>');
					isValid = false;
				}
			});
		
			$('.checkbox-master[required]').each(function () {
				if ($(this).find('input[type="checkbox"]:checked').length === 0) {
					$(this).before('<div class="error-message">Bitte mindestens eine Option auswählen</div>');
					isValid = false;
				}
			});
		
			$('.checkbox-master[required] input[type="checkbox"]').change(function () {
				if ($(this).closest('.checkbox-master').find('input[type="checkbox"]:checked').length > 0) {
					$(this).closest('.checkbox-master').siblings('.error-message').remove();
				}
			});
		
			$('.application-basic-information [required]').each(function () {
				if ($(this).val() === '') {
					var placeholderValue = $(this).attr('placeholder');
					$(this).before('<div class="error-message" style="color: red;">' + placeholderValue + ' darf nicht leer sein.</div>');
					isValid = false;
				}
			});
		
			// Additional check for acceptance
			if (!$('#checkbox_1').is(':checked') || !$('#checkbox_2').is(':checked')) {
				$('.acceptance-wrap').append('<div class="error-message">Bitte bestätige unsere Bedingungen.</div>');
				isValid = false;
			} else {
				$('.acceptance-wrap .error-message').remove();
			}
		
			if (!isValid) {
				e.preventDefault(); // Stop form submission
			}
		});
		


		$('fieldset').on('change', '.select-styled', function() {
			$(this).siblings('.error-message').remove();
		});

	
		function updateCheckboxesAndHiddenInputs() {
			var allChecked = true;
			// Loop through each checkbox with name attribute 'radio_group_x'
			for(var i = 1; i <= 3; i++) {
				var isChecked = $('input[id="checkbox_' + i + '"]').is(':checked');
				// Update the corresponding hidden input
				$('#checkbox_' + i + '_accepted').val(isChecked ? 'yes' : 'no');
				// Check if all required checkboxes are checked
				if(!isChecked) {
					allChecked = false;
				}
			}
			// Check if allChecked is false, if so display error message
			if(!allChecked) {
				// If there's no error message yet, add it
				if($('.acceptance-wrap .error-message').length === 0) {
					$('.acceptance-wrap').append('<div class="error-message">Bitte bestätige unsere Bedingungen.</div>');
				}
			} else {
				// If all checkboxes are checked, remove error message if it exists
				$('.acceptance-wrap .error-message').remove();
			}
		}
	
		// Event listener for any of the three checkboxes
		$('input[id^="checkbox_"]').on('change', function() {
			updateCheckboxesAndHiddenInputs();
		});
		
		// Initial check on page load
		updateCheckboxesAndHiddenInputs();
	});
	


	jQuery(document).ready(function($) {
		// Iterate over each custom select wrapper
		$('.application-form-wrap .select').each(function() {
			var $fieldGroup = $(this).closest('.field-group'); // The '.field-group' that contains the custom select
			var $hiddenInput = $fieldGroup.find('input[type="hidden"]'); // The hidden input within the '.field-group'
	
			// When a list item in the custom dropdown is clicked...
			$(this).find('.select-options li').on('click', function(e) {
				e.stopPropagation();
				// Get the 'rel' attribute of the clicked item, which corresponds to the option value
				var selectedValue = $(this).attr('rel');
				// Update the hidden input with this value
				$hiddenInput.val(selectedValue);
			});
		});
	
	
		// Code to close the dropdown when clicking outside
		$(document).click(function() {
			$('.aplication-form-wrap .select-style-other.active').each(function() {
				$(this).removeClass('active').next('ul.select-options').hide();
			});
		});
	
		$('.field-group .checkbox-wrapper').on('change', 'input[type="checkbox"]', function() {
			var $fieldGroup = $(this).closest('.field-group');
			// Collect all checked values within this '.field-group'
			var checkedValues = $fieldGroup.find('input[type="checkbox"]:checked').map(function() {
				return $(this).val();
			}).get().join(', '); // Join the checked values with a comma and space
			
			// Set the collected values as the value of the hidden input
			$fieldGroup.find('input[type="hidden"]').val(checkedValues);
		});




		// Function to check for 'sonstiges' or 'freifeld' and show/hide '.sonstiges_group'
		function checkForSonstigesOrFreifeldInGroup(fieldGroup) {
			var showGroup = false;
			var regex = /sonstiges|sonstige|freifeld/i; // regex to match 'sonstiges' or 'freifeld' ignoring case

			// Check elements with class .first-hidden in the given .field-group
			fieldGroup.querySelectorAll('.first-hidden').forEach(function (hiddenInput) {
				if (regex.test(hiddenInput.value)) {
					showGroup = true;
				}
			});

			// Find .sonstiges_group in the same .field-group and show or hide
			var sonstigesGroup = fieldGroup.querySelector('.sonstiges_group');
			if (sonstigesGroup) { // Check if .sonstiges_group exists in this group
				if (showGroup) {
					sonstigesGroup.style.display = 'block';
				} else {
					sonstigesGroup.style.display = 'none';
				}
			}

		}

		// Function to invoke checking for each .field-group
		function checkAllFieldGroups() {
			document.querySelectorAll('.field-group').forEach(checkForSonstigesOrFreifeldInGroup);
		}

		// Run the function when the page is loaded
		document.addEventListener('DOMContentLoaded', function () {
			checkAllFieldGroups();
		});

		// Periodic checking after a certain interval
		setInterval(checkAllFieldGroups, 1000);

	});




	
	jQuery(document).ready(function($) {

		function collectFieldGroupValues() {
			var listItems = [];
			// Create an object to track added values
			var addedValues = {};
	
			$('.field-group').each(function() {
				var label = $(this).find('legend').first().text();
				var firstValue = $(this).find('input.first-hidden').val();
				var secondValue = $(this).find('input.second-hidden').val();
				var thirdHiddenValue = $(this).find('input.third-hidden').val();
				var thirdTextValue = $(this).find('input.third-text').val();
	
				var listItem = '<li><span>' + label + ':</span>';
	
				if (firstValue && !addedValues[firstValue]) {
					listItem += ' ' + firstValue;
					addedValues[firstValue] = true;
				}
	
				if (secondValue && !addedValues[secondValue]) {
					listItem += ' ' + secondValue;
					addedValues[secondValue] = true;
				}
	
				if (thirdTextValue && !addedValues[thirdTextValue]) {
					listItem += ' ' + thirdTextValue;
					addedValues[thirdTextValue] = true;
				} else if (thirdHiddenValue && !addedValues[thirdHiddenValue]) {
					listItem += 'Sonstige: ' + thirdHiddenValue;
					addedValues[thirdHiddenValue] = true;
				}
	
				listItem += '</li>';
				listItems.push(listItem);
			});
	
			addedValues = {};
	
			// Display the list
			// console.log(listItems);
	
			// Create the full list
			return '<ul>' + listItems.join('') + '</ul>';
		}
	
		// Function to update application data
		function updateApplicationData() {
			var formattedList = collectFieldGroupValues();
			// console.log("Collected data:", formattedList); // Adding console.log
	
			$('#application-data').val(formattedList); // Set value, not HTML
		}
	
		// Initialize on page load
		updateApplicationData();
	
		// Update on value change
		$('.field-group').on('change', 'input, select', function() {
			updateApplicationData();
		});
	
	});
	






	$(document).ready(function() {
		// For each select in the form
		$('.application-form-wrap .field-group select').each(function() {
			var $this = $(this),
				isMultiple = $this.is('[multiple]'),
				numberOfOptions = $this.children('option').length;
	
			$this.addClass('select-hidden');
			$this.wrap('<div class="select"></div>');
			$this.after('<div class="select-styled">Auswählen</div>');
	
			var $styledSelect = $this.next('div.select-styled');
	
			var $list = $('<ul />', {
				'class': 'select-options'
			}).insertAfter($styledSelect);
	
			// Create options list
			for (var i = 0; i < numberOfOptions; i++) {
				$('<li />', {
					text: $this.children('option').eq(i).text(),
					rel: $this.children('option').eq(i).val()
				}).appendTo($list)
				.data('select', $this); // Add a reference to select in data for li
			}
	
			var $listItems = $list.children('li');
	
			// Handle click on styled select
			$styledSelect.click(function(e) {
				e.stopPropagation();
				$('div.select-styled.active').not(this).each(function(){
					$(this).removeClass('active').next('ul.select-options').hide();
				});
				$(this).toggleClass('active').next('ul.select-options').toggle();
				if($(this).hasClass('active')) {
					$(this).text('');
				} else {
					var selectedText = $this.children('option:selected').text() || 'Auswählen';
					$(this).text(selectedText);
				}
			});
	
			// Handle click on list options
			$listItems.click(function(e) {
				e.stopPropagation();
				var $item = $(this),
					val = $item.attr('rel'),
					$select = $item.data('select'),
					$hiddenInput = $select.closest('.field-group').find('.first-hidden');
	
				if (isMultiple) {
					var selectedValues = $hiddenInput.val() ? $hiddenInput.val().split(', ') : [];
					if ($item.hasClass('is-selected')) {
						$item.removeClass('is-selected');
						selectedValues = selectedValues.filter(function(e) { return e !== val });
					} else {
						$item.addClass('is-selected');
						selectedValues.push(val);
					}
					$hiddenInput.val(selectedValues.join(','));
					$styledSelect.text(selectedValues.map(function(val) { 
						return $select.find('option[value="'+val+'"]').text();
					}).join(', ') || 'Auswählen');
				} else {
					$listItems.removeClass('is-selected');
					$item.addClass('is-selected');
					$hiddenInput.val(val);
					$styledSelect.text($item.text() || 'Auswählen');
					$list.hide();
					$styledSelect.removeClass('active');
				}
				$select.val(val).change();
			});
	
			// Clicking outside the select hides the dropdown list
			$(document).click(function() {
				$styledSelect.removeClass('active');
				$list.hide();
				if ($styledSelect.text() === '') {
					$styledSelect.text('Auswählen');
				}
			});
		});
	});
	
	
	

	

jQuery(document).ready(function($) {
	// Listen for input event on text fields with class 'first-text' within '.field-group'
	$('.field-group').on('input', 'input[type="text"].first-text', function() {
		// Get the value from the text field
		var textValue = $(this).val();
		// Find the nearest hidden input with class 'first-hidden' and set the value
		$(this).closest('.field-group').find('input[type="hidden"].first-hidden').val(textValue);
	});

	$('.field-group').on('input', 'input[type="text"].second-text', function() {
		// Get the value from the text field
		var textValue = $(this).val();
		// Find the nearest hidden input with class 'first-hidden' and set the value
		$(this).closest('.field-group').find('input[type="hidden"].second-hidden').val(textValue);
	});


	$('.field-group').on('input', 'input[type="text"].third-text', function() {
		// Get the value from the text field
		var textValue = $(this).val();
		// Find the nearest hidden input with class 'first-hidden' and set the value
		$(this).closest('.field-group').find('input[type="hidden"].third-hidden').val(textValue);
	});
});



// First part (Your code generating dropdown menu)
$('#radius-wrap select').each(function(){
	var $this = $(this), numberOfOptions = $(this).children('option').length;

	$this.addClass('select-hidden'); 
	$this.wrap('<div class="select"></div>');
	$this.after('<div class="select-styled"></div>');

	var $styledSelect = $this.next('div.select-styled');
	$styledSelect.text($this.children('option').eq(0).text());

	var $list = $('<ul />', {
		'class': 'select-options'
	}).insertAfter($styledSelect);

	// Wrapping the $list (ul) inside a div
	$list.wrap('<div class="ul-wrapper"></div>');

	for (var i = 0; i < numberOfOptions; i++) {
		$('<li />', {
			text: $this.children('option').eq(i).text(),
			rel: $this.children('option').eq(i).val()
		}).appendTo($list);
		if ($this.children('option').eq(i).is(':selected')){
			$('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected');
		}
	} 

	var $listItems = $list.children('li');

	$styledSelect.click(function(e) {
		e.stopPropagation();
		$('#radius-wrap').addClass('active');
		var $ulWrapper = $list.parent('.ul-wrapper');
		$('div.ul-wrapper.active').not($ulWrapper).removeClass('active').find('ul.select-options').hide();
		$ulWrapper.toggleClass('active');
		$(this).toggleClass('active').next('ul.select-options').toggle();

		// Add console.log here to check when .select-styled is clicked
		console.log('Clicked .select-styled');

		setTimeout(function() {
			$('#js-filtering-home').trigger('click');
		}, 3000);
	});

	$listItems.click(function(e) {
		e.stopPropagation();
		$('#radius-wrap').removeClass('active');
		$styledSelect.text($(this).text()).removeClass('active');
		$this.val($(this).attr('rel'));
		$list.find('li.is-selected').removeClass('is-selected');
		$list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected');
		$('.select-styled.active').removeClass('active');
		$('ul.select-options').hide();
		$('div.ul-wrapper.active').removeClass('active').find('ul.select-options').hide();

		// Add console.log here to check when li in .select-options is clicked
		// console.log('Clicked li in .select-options');
	});

	$(document).click(function() {
		$styledSelect.removeClass('active');
		$list.hide();
		$('#radius-wrap').removeClass('active');
		$('div.ul-wrapper.active').removeClass('active').find('ul.select-options').hide();
		
		// Add console.log here to check when document is clicked (outside .select-styled and .select-options)
		// console.log('Clicked document');
	});

	// Add event listener for "radius" field change
	$this.on('change', function() {
		const selectedOptionText = $this.children('option:selected').text();
		// console.log(`Selected radius value: ${selectedOptionText}`);
		// console.log($this);
	});
});


//Second part

$(document).ready(function() {
	// Function to save the selected value to localStorage
	function saveSelectedValue() {
		$('#radius-items select').each(function() {
			var selectedValue = $(this).val();
			localStorage.setItem('selectedRadiusValue', selectedValue);
		});
	}

	// Function to read the value from localStorage and set it as selected
	function restoreSelectedValue() {
		var selectedValue = localStorage.getItem('selectedRadiusValue');
		if (selectedValue) {
			$('#radius-items select').each(function() {
				$(this).val(selectedValue);
				$(this).next('.select-styled-radius-items').text($(this).children('option[value="' + selectedValue + '"]').text());
			});
		}
	}

	// Call the function on page load
	restoreSelectedValue();

	$('#radius-items select').each(function() {
		var $this = $(this),
			numberOfOptions = $(this).children('option').length;

		$this.addClass('select-hidden-radius-items');
		$this.wrap('<div class="select-radius-items"></div>');
		$this.after('<div class="select-styled-radius-items"></div>');

		var $styledSelect = $this.next('div.select-styled-radius-items');
		$styledSelect.text($this.children('option').eq(0).text());

		var $list = $('<ul />', {
			'class': 'select-options-radius-items'
		}).insertAfter($styledSelect);

		$list.wrap('<div class="ul-wrapper-radius-items"></div>');

		for (var i = 0; i < numberOfOptions; i++) {
			$('<li />', {
				text: $this.children('option').eq(i).text(),
				rel: $this.children('option').eq(i).val()
			}).appendTo($list);
			if ($this.children('option').eq(i).is(':selected')) {
				$('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected-radius-items');
			}
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			$('#radius-items').addClass('active-radius-items');
			var $ulWrapper = $list.parent('.ul-wrapper-radius-items');
			$('div.ul-wrapper-radius-items.active').not($ulWrapper).removeClass('active').find('ul.select-options-radius-items').hide();
			$ulWrapper.toggleClass('active');
			$(this).toggleClass('active').next('ul.select-options-radius-items').toggle();

			console.log('Clicked .select-options-radius-items');

			// After clicking on the dropdown list, save the currently selected value
			saveSelectedValue();

			setTimeout(function() {
				var selectedValue = $('#teams-wrap .select-styled-radius-items').text().trim();
				if (selectedValue !== '') {
					$('#js-filtering-home').trigger('click');
				} else {
					selectedValue = $('#types-wrap .select-styled-radius-items').text().trim();
					if (selectedValue !== '') {
						$('#js-filtering-home').trigger('click');
					} else {
						selectedValue = $('#homeoffice-wrap .select-styled-radius-items').text().trim();
						if (selectedValue !== '') {
							$('#js-filtering-home').trigger('click');
						}
					}
				}
			}, 3000);
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$('#radius-items').removeClass('active-radius-items');
			$styledSelect.text($(this).text()).removeClass('active');
			$this.val($(this).attr('rel'));
			$list.find('li.is-selected-radius-items').removeClass('is-selected-radius-items');
			$list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected-radius-items');
			$('.select-styled-radius-items.active').removeClass('active');
			$('ul.select-options-radius-items').hide();
			$('div.ul-wrapper-radius-items.active').removeClass('active').find('ul.select-options-radius-items').hide();
		});

		$(document).click(function() {
			$styledSelect.removeClass('active');
			$list.hide();
			$('#radius-items').removeClass('active-radius-items');
			$('div.ul-wrapper-radius-items.active').removeClass('active').find('ul.select-options-radius-items').hide();
		});
	});
});



//Second part 2
$(document).ready(function() {
	// Function to save the selected value to localStorage
	function saveSelectedValue() {
		$('#radius-items-2 select').each(function() {
			var selectedValue = $(this).val();
			localStorage.setItem('selectedRadiusValue', selectedValue);
		});
	}

	// Function to read the value from localStorage and set it as selected
	function restoreSelectedValue() {
		var selectedValue = localStorage.getItem('selectedRadiusValue');
		if (selectedValue) {
			$('#radius-items-2 select').each(function() {
				$(this).val(selectedValue);
				$(this).next('.select-styled-radius-items').text($(this).children('option[value="' + selectedValue + '"]').text());
			});
		}
	}

	// Call the function on page load
	restoreSelectedValue();

	$('#radius-items-2 select').each(function() {
		var $this = $(this),
			numberOfOptions = $(this).children('option').length;

		$this.addClass('select-hidden-radius-items');
		$this.wrap('<div class="select-radius-items"></div>');
		$this.after('<div class="select-styled-radius-items"></div>');

		var $styledSelect = $this.next('div.select-styled-radius-items');
		$styledSelect.text($this.children('option').eq(0).text());

		var $list = $('<ul />', {
			'class': 'select-options-radius-items'
		}).insertAfter($styledSelect);

		$list.wrap('<div class="ul-wrapper-radius-items"></div>');

		for (var i = 0; i < numberOfOptions; i++) {
			$('<li />', {
				text: $this.children('option').eq(i).text(),
				rel: $this.children('option').eq(i).val()
			}).appendTo($list);
			if ($this.children('option').eq(i).is(':selected')) {
				$('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected-radius-items');
			}
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			$('#radius-items-2').addClass('active-radius-items');
			var $ulWrapper = $list.parent('.ul-wrapper-radius-items');
			$('div.ul-wrapper-radius-items.active').not($ulWrapper).removeClass('active').find('ul.select-options-radius-items').hide();
			$ulWrapper.toggleClass('active');
			$(this).toggleClass('active').next('ul.select-options-radius-items').toggle();

			console.log('Clicked .select-options-radius-items');

			// After clicking on the dropdown list, save the currently selected value
			saveSelectedValue();

			setTimeout(function() {
				var selectedValue = $('#teams-wrap .select-styled-radius-items').text().trim();
				if (selectedValue !== '') {
					$('#js-filtering-home').trigger('click');
				} else {
					selectedValue = $('#types-wrap .select-styled-radius-items').text().trim();
					if (selectedValue !== '') {
						$('#js-filtering-home').trigger('click');
					} else {
						selectedValue = $('#homeoffice-wrap .select-styled-radius-items').text().trim();
						if (selectedValue !== '') {
							$('#js-filtering-home').trigger('click');
						}
					}
				}
			}, 3000);
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$('#radius-items-2').removeClass('active-radius-items');
			$styledSelect.text($(this).text()).removeClass('active');
			$this.val($(this).attr('rel'));
			$list.find('li.is-selected-radius-items').removeClass('is-selected-radius-items');
			$list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected-radius-items');
			$('.select-styled-radius-items.active').removeClass('active');
			$('ul.select-options-radius-items').hide();
			$('div.ul-wrapper-radius-items.active').removeClass('active').find('ul.select-options-radius-items').hide();
		});

		$(document).click(function() {
			$styledSelect.removeClass('active');
			$list.hide();
			$('#radius-items-2').removeClass('active-radius-items');
			$('div.ul-wrapper-radius-items.active').removeClass('active').find('ul.select-options-radius-items').hide();
		});
	});
});


//Second part 3

$(document).ready(function() {
	// Function to save the selected value to localStorage
	function saveSelectedValue() {
		$('#radius-items-3 select').each(function() {
			var selectedValue = $(this).val();
			localStorage.setItem('selectedRadiusValue', selectedValue);
		});
	}

	// Function to read the value from localStorage and set it as selected
	function restoreSelectedValue() {
		var selectedValue = localStorage.getItem('selectedRadiusValue');
		if (selectedValue) {
			$('#radius-items-3 select').each(function() {
				$(this).val(selectedValue);
				$(this).next('.select-styled-radius-items').text($(this).children('option[value="' + selectedValue + '"]').text());
			});
		}
	}

	// Call the function on page load
	restoreSelectedValue();

	$('#radius-items-3 select').each(function() {
		var $this = $(this),
			numberOfOptions = $(this).children('option').length;

		$this.addClass('select-hidden-radius-items');
		$this.wrap('<div class="select-radius-items"></div>');
		$this.after('<div class="select-styled-radius-items"></div>');

		var $styledSelect = $this.next('div.select-styled-radius-items');
		$styledSelect.text($this.children('option').eq(0).text());

		var $list = $('<ul />', {
			'class': 'select-options-radius-items'
		}).insertAfter($styledSelect);

		$list.wrap('<div class="ul-wrapper-radius-items"></div>');

		for (var i = 0; i < numberOfOptions; i++) {
			$('<li />', {
				text: $this.children('option').eq(i).text(),
				rel: $this.children('option').eq(i).val()
			}).appendTo($list);
			if ($this.children('option').eq(i).is(':selected')) {
				$('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected-radius-items');
			}
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			$('#radius-items-3').addClass('active-radius-items');
			var $ulWrapper = $list.parent('.ul-wrapper-radius-items');
			$('div.ul-wrapper-radius-items.active').not($ulWrapper).removeClass('active').find('ul.select-options-radius-items').hide();
			$ulWrapper.toggleClass('active');
			$(this).toggleClass('active').next('ul.select-options-radius-items').toggle();

			console.log('Clicked .select-options-radius-items');

			// After clicking on the dropdown list, save the currently selected value
			saveSelectedValue();

			setTimeout(function() {
				var selectedValue = $('#teams-wrap .select-styled-radius-items').text().trim();
				if (selectedValue !== '') {
					$('#js-filtering-home').trigger('click');
				} else {
					selectedValue = $('#types-wrap .select-styled-radius-items').text().trim();
					if (selectedValue !== '') {
						$('#js-filtering-home').trigger('click');
					} else {
						selectedValue = $('#homeoffice-wrap .select-styled-radius-items').text().trim();
						if (selectedValue !== '') {
							$('#js-filtering-home').trigger('click');
						}
					}
				}
			}, 3000);
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$('#radius-items-3').removeClass('active-radius-items');
			$styledSelect.text($(this).text()).removeClass('active');
			$this.val($(this).attr('rel'));
			$list.find('li.is-selected-radius-items').removeClass('is-selected-radius-items');
			$list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected-radius-items');
			$('.select-styled-radius-items.active').removeClass('active');
			$('ul.select-options-radius-items').hide();
			$('div.ul-wrapper-radius-items.active').removeClass('active').find('ul.select-options-radius-items').hide();
		});

		$(document).click(function() {
			$styledSelect.removeClass('active');
			$list.hide();
			$('#radius-items-3').removeClass('active-radius-items');
			$('div.ul-wrapper-radius-items.active').removeClass('active').find('ul.select-options-radius-items').hide();
		});
	});
});

	$(document).ready(function() {
		setTimeout(function() {
			// Getting values
			var teamElement = $('#js-job-team');
			var typeElement = $('#js-job-type');
			var homeofficeElement = $('#js-job-homeoffice');

			// Getting text from elements if they exist, otherwise default values
			var teamValue = teamElement.length && teamElement.text().trim() !== '' ? toTitleCase(teamElement.text().trim()) : 'Alle Berufsfelder';
			var typeValue = typeElement.length && typeElement.text().trim() !== '' ? toTitleCase(typeElement.text().trim()) : 'Alle Anstellungsarten';
			var homeofficeValue = homeofficeElement.length && homeofficeElement.text().trim() !== '' ? toTitleCase(homeofficeElement.text().trim()) : '';

			// Inserting values into respective places
			$('#teams-wrap .select-styled-radius-items').text(teamValue);
			$('#types-wrap .select-styled-radius-items').text(typeValue);
			if (homeofficeValue !== '') {
				$('#homeoffice-wrap .select-styled-radius-items').text(homeofficeValue);
			}
		}, 1500); // 2000 milliseconds (2 seconds)
	});



	// Function to convert to title case
	function toTitleCase(str) {
		return str.replace(/\b\w/g, function(txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}



	document.addEventListener('DOMContentLoaded', function() {

		function setupCheckboxListener(checkboxName, checkboxValue, containerId) {
			var checkboxes = document.querySelectorAll(`input[name="${checkboxName}"][value="${checkboxValue}"]`);
			
			checkboxes.forEach(checkbox => {
				var textContainer = document.getElementById(containerId);
	
				checkbox.addEventListener('change', function() {
					textContainer.style.display = checkbox.checked ? 'block' : 'none';
				});
			});
		}
	
		setupCheckboxListener('ausbildung[]', 'Sonstiges', 'sonstiges-text-container');
		setupCheckboxListener('fremdsprachen[]', 'Sonstiges', 'fremdsprachen-text-container');
		setupCheckboxListener('computerkenntnisse[]', 'Sonstiges', 'computerkenntnisse-text-container');
	});
	
	
	

	if($('#js-topJobs .sectionPopular__single').length) {
		var swiper = new Swiper("#js-topJobs", {
			loop: true,
			slidesPerView: 5,
			effect: 'coverflow',
			grabCursor: false,
			centeredSlides: true,
			slidesPerView: 'auto',
			coverflowEffect: {
				rotate: 0,
				stretch: 100,
				depth: 200,
				modifier: 5,
				slideShadows: false,
			},
			pagination: {
			el: ".swiper-pagination",
			clickable: true,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					effect: 'fade',
				},
				1280: {
					effect: 'coverflow',
				}
			},
			on: {
			click(event) {

				swiper.slideTo(this.clickedIndex);
			},
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

		});
	}

	if($('#js-topJobs-mobile .sectionPopular__single').length) {
		var swiper = new Swiper("#js-topJobs-mobile", {
			loop: true,
			slidesPerView: 1,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}

	if($('#js-swiper-clients2 .logo_wrapper').length){
		var swiper = new Swiper("#js-swiper-clients2", {
			slidesPerView: 'auto',
			spaceBetween: 60,
			loop: true,
			loopedSlides: 100,
			autoplay: {
				delay: 0,
				disableOnInteraction: false
			},
			speed: 4000,
		  });
	}

	if($('#js-swiper-clients3 .logo_wrapper').length) {
		var swiper = new Swiper("#js-swiper-clients3", {
			slidesPerView: 'auto',
			spaceBetween: 60,
			loop: true,
			loopedSlides: 100,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
				reverseDirection: true,
			},
			speed: 4000,
		});
	}

	if($('#js-swiper-company .sectionTopJobs__company').length) {
		var swiper = new Swiper("#js-swiper-company", {
			slidesPerView: "auto",
			centeredSlides: true,
			spaceBetween: 30,
			pagination: {
			  el: ".swiper-pagination",
			  clickable: true,
			},
		});
	}

	
	// AUTOCOMPLETE START
	// function fetchAndProcessJobs(lat1, lon1) {
	// 	const selectedRadius = parseInt(document.querySelector('#radius').value);
	
	// 	return fetchAllTerms('/wp-json/wp/v2/standort?per_page=100&hide_empty=true&acf')
	// 		.then(terms => {
	// 			const validTerms = [];
	
	// 			terms.forEach(term => {
	// 				if (term.acf) {
	// 					const lat2 = parseFloat(term.acf.latitude);
	// 					const lon2 = parseFloat(term.acf.longitude);
	// 					const distance = getDistance(lat1, lon1, lat2, lon2);
	
	// 					if (distance <= selectedRadius / 1000) {
	// 						validTerms.push(term);
	// 					}
	// 				}
	// 			});
	// 			return validTerms;
	
	// 		})
	// 		.catch(error => {
	// 			console.error("Error terms:", error);
	// 			return [];
	// 		});
	// }
	

	
	function evaluateLocationAndFetchData() {
		const locationInput = document.querySelector('#locations');
	
		// Check if there is a value in the #locations element
		if (locationInput && locationInput.value) {
	
			const latitude = parseFloat(locationInput.getAttribute('data-latitude'));
			const longitude = parseFloat(locationInput.getAttribute('data-longitude'));
	
			if (latitude && longitude) {
				fetchAndProcessJobs(latitude, longitude);
			} else {
				// console.error('Latitude and Longitude not available for the location.');
			}
		}
	}
	
	
	function checkAndFetchTerms() {
		const locationInput = document.querySelector('#locations');
		if (locationInput.value) {
			const latitude = parseFloat(locationInput.getAttribute('data-latitude'));
			const longitude = parseFloat(locationInput.getAttribute('data-longitude'));
	
			if (latitude && longitude) {
				fetchAndProcessJobs(latitude, longitude);
			}
		}
	}
	
	


	document.addEventListener('DOMContentLoaded', function() {
		evaluateLocationAndFetchData();
	
		const locationsInput = document.querySelector('#locations');
	
		if (locationsInput) {
			locationsInput.addEventListener('input', function() {
				// Update params.latitude and params.longitude
				const latitude = locationsInput.getAttribute('data-latitude');
				const longitude = locationsInput.getAttribute('data-longitude');
	
				if (latitude && longitude) {
					params.latitude = parseFloat(latitude);
					params.longitude = parseFloat(longitude);
				} else {
					// console.error('No data.');
				}
	
				evaluateLocationAndFetchData();
			});
		} else {
			// console.error('Element #locations not found!');
		}
	
		let element = document.querySelector('#radius');
		if (element) {
			element.addEventListener('change', function() {
				// your remaining code
			});
		} else {
			// console.error('Element #radius not found!');
		}
	});
	
	$(document).ready(function() {
		// Listening for value changes in the first selection field
		$('#acf-field_65575693a213a').on('change', function() {
			// Getting the selected value
			var selectedValue = $(this).val();
	
			// Creating a new li element with the selected value
			var newListItem = $('<li class="select2-selection__choice" title="' + selectedValue + '"><span class="select2-selection__choice__remove" role="presentation">×</span><span class="acf-selection">' + selectedValue + '</span></li>');
	
			// Adding the new li element to the list in the second selection field
			$('#acf-field_656460515c7a2 .select2-selection__rendered').append(newListItem);
		});
	});
	

	function fetchAllTerms(url) {
		return fetch(url)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			});
	}
	
	function getDistance(lat1, lon1, lat2, lon2) {
		const R = 6371;
		const dLat = deg2rad(lat2 - lat1);
		const dLon = deg2rad(lon2 - lon1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = R * c;
		return distance;
	}
	
	function deg2rad(deg) {
		return deg * (Math.PI / 180);
	}
	


	document.addEventListener('DOMContentLoaded', function() {
		let element = document.querySelector('#radius');
		if (element) {
			element.addEventListener('change', function() {
				// the rest of your code
			});
		} else {
			// console.error('Element #radius not found!');
		}
	});


	// AUTOCOMPLE END

	// mobile forms on page jobs

	$(document).on('click', '#js-filtering-home-mobile', function(e) {
		e.preventDefault();
	
		$('#exampleModalFilters').modal('hide');
		$('#js-filtering-home-mobile').addClass('loading-btn');
		$('.spinner-wrap').addClass('loading');
	
		const locationInput = document.querySelector('#locations');
		const latitude = parseFloat(locationInput.getAttribute('data-latitude'));
		const longitude = parseFloat(locationInput.getAttribute('data-longitude'));
		const radius = $('#radius').val();
	
		if (!radius || radius === "0") {
			handleAjaxRequest([], [locationInput.value]);
		} else if (latitude && longitude) {
			fetchAndProcessJobs(latitude, longitude).then(validTerms => {
				const validTermIds = validTerms.map(term => term.id);
				const validTermNames = validTerms.map(term => term.name);
	
				handleAjaxRequest(validTermIds, validTermNames);
			});
		} else {
			handleAjaxRequest();
		}
	
		function sanitizeInput(input) {
			if (typeof input !== 'undefined' && input !== null) {
				const specialCharacters = {
					'Ãƒâ€žÃ¢â‚¬Â¦': 'a',
					'Ãƒâ€žÃ¢â‚¬Â¡': 'c',

				};
	
				const sanitizedInput = input.replace(/[^\w\s]/gi, (match) => {
					return specialCharacters[match] || '';
				});
	
				return sanitizedInput;
			} else {
				return '';
			}
		}
	


function handleAjaxRequest(validTermIds = [], validTermNames = []) {
	// Check if the #locations element exists, then retrieve its value
	const locationsElement = $('#locations');
	if (locationsElement.length) {
		const locations = locationsElement.val();
		// Continue processing here...
	} else {
		// Handle the situation when the element does not exist
		console.log("#locations element does not exist.");
	}
			const teams = $('#teams').val();
			const types = $('#types').val();
			const min_salary = $("#min_salary").val();
			const max_salary = $("#max_salary").val();
			const input_search = $('#input_search').val();
			const radius = $('#radius').val();
			const homeoffice = $('#homeoffice').val();
			const unique_params = $('unique_param').val();
	
			let distanceResults;
	
			if (!radius || radius === "0" || validTermNames.length === 0) {
				distanceResults = locations; 
			} else {
				console.log('Before assignment:', validTermNames);
				distanceResults = validTermNames.join(',');
				console.log('After assignment:', distanceResults);
			}
	
			const params = {
				locations: locations,
				teams: teams,
				types: types,
				min_salary: min_salary,
				max_salary: max_salary,
				input_search: input_search,
				distanceResults: distanceResults,
				distance: radius,
				homeoffice: homeoffice,
				latitude: latitude,
				longitude: longitude,
				unique_params: unique_params,
			};
			
			const queryString = $.param(params);
			const url = `/jobs/?${queryString}`;
			const ajax_url = '/wp-admin/admin-ajax.php';
	
			$.ajax({
				type: 'GET',
				url: ajax_url,
				dataType: 'html',
				data: {
					'action': 'filter_projects',
					'locations': locations,
					'teams': teams,
					'types': types,
					'min_salary': min_salary,
					'max_salary': max_salary,
					'input_search': input_search,
					'distance-results': validTermNames.join(','),
					'distance': radius,
					'homeoffice': homeoffice,
					'unique_params': unique_params,
				},
				success: function(res) {
					$('.preloader').addClass('hidden');
					$('#js-filtering-home').removeClass('loading-btn');
					$('.spinner-wrap').removeClass('loading');
	
					window.history.pushState({}, '', url);
	
					
					$('#exampleModalFilters').modal('hide');
					location.reload();
				},
				error: function() {
					$('.preloader').addClass('hidden');
					$('#js-filtering-home').removeClass('loading-btn');
					$('.spinner-wrap').removeClass('loading');
	
					$('.jobListing').html('<div class="productWrapper noOfferts"><div class="productCarousel"><div class="container"><div class="row"><h1 class="text-center">Leider haben wir keine passenden Angebote</h1></div></div></div></div>');
					$('#load_using_ajax').hide();
					$('.sectionJobOffers__details').show();
					var numResults = "0";
					$('#js-job-number').text(numResults);
					$('#exampleModalFilters').modal('hide');
					location.reload();
				}
			});
			
		}
	
		$('#ajax-search-form-locations').change(function() {
			const value = $(this).val();
			let url = window.location.href;
			if (url.indexOf('?') !== -1) {
				url = url.replace(/ajax-search-form-locations=\d+/, 'ajax-search-form-locations=' + value);
			} else {
				url += '?ajax-search-form-locations=' + value;
			}
			window.history.pushState({}, '', url);
		});
	});
	
	
	
	
	


	$(document).on('click', '#js-filtering-home-page', function (e) {
		e.preventDefault();

		$('#js-filtering-home').addClass('loading-btn');
		$('.spinner-wrap').addClass('loading');

		const locationInput = document.querySelector('#locations');
		const latitude = parseFloat(locationInput.getAttribute('data-latitude'));
		const longitude = parseFloat(locationInput.getAttribute('data-longitude'));
	
		const radius = $('#radius').val();

		if (!radius || radius === "0") {
			handleAjaxRequest([], [locationInput.value]); 
		} else if (latitude && longitude) {
			fetchAndProcessJobs(latitude, longitude).then(validTerms => {
				const validTermIds = validTerms.map(term => term.id);
				const validTermNames = validTerms.map(term => term.name);
	
				handleAjaxRequest(validTermIds, validTermNames);
			});
		} else {
			handleAjaxRequest(); 
		}
	
		function handleAjaxRequest(validTermIds = [], validTermNames = []) {
			const locations = $('#locations').val();
			const teams = $('#teams').val();
			const types = $('#types').val();
			const min_salary = $("#min_salary").val();
			const max_salary = $("#max_salary").val();
			const input_search = $('#input_search').val();
			const radius = $('#radius').val();
			let distanceResults;


			var latitude = document.getElementById('locations').getAttribute('data-latitude');
			var longitude = document.getElementById('locations').getAttribute('data-longitude');
			// var actionUrl = "/jobs?latitude=" + latitude + "&longitude=" + longitude;
			// this.action = actionUrl;

			if (!radius || radius === "0" || validTermNames.length === 0) {
				distanceResults = locations; 
			} else {
				distanceResults = validTermNames.join(',');
			}


	
			const params = {
				locations: locations,
				teams: teams,
				types: types,
				min_salary: min_salary,
				max_salary: max_salary,
				input_search: input_search,
				distanceResults: distanceResults,
				distance: radius,
				latitude: latitude,
				longitude: longitude,
			};
	
			// console.log(params);
			
			const queryString = $.param(params);
			const url = `/jobs/?${queryString}`;
	
			$.ajax({
				type: 'GET',
				url: ajax_url,
				dataType: 'html',
				data: {
					'action': 'filter_projects',
					'locations': locations,
					'teams': teams,
					'types': types,
					'min_salary': min_salary,
					'max_salary': max_salary,
					'input_search': input_search,
					'distance-results': validTermNames.join(','),
					'distance': radius,
				},
				success: function(res) {
					$('#js-filtering-home').removeClass('loading-btn');
					$('.spinner-wrap').removeClass('loading');

					window.history.pushState({}, '', url);
					window.location.href = url;
					$('.jobListing').html(res);
					$('#results').hide();
					$('#load_using_ajax').hide();
					$('.sectionJobOffers__details').show();
				},
				error: function() {
					$('#js-filtering-home').removeClass('loading-btn');
					$('.spinner-wrap').removeClass('loading');

					$('.jobListing').html('<div class="productWrapper noOfferts"><div class="productCarousel"><div class="container"><div class="row"><h1 class="text-center">Leider haben wir keine passenden Angebote</h1></div></div></div></div>');
					$('#load_using_ajax').hide();
					$('.sectionJobOffers__details').show();
					$('#js-job-number').text('0');
					return;
				}
			});
		}
	
		$('#ajax-search-form-locations').change(function () {
			var value = $(this).val();
			var url = window.location.href;
			var newUrl;
	
			if (url.indexOf('?') !== -1) {
				newUrl = url.replace(/ajax-search-form-locations=\d+/, 'ajax-search-form-locations=' + value);
			} else {
				newUrl = url + '?ajax-search-form-locations=' + value;
			}
	
			window.history.pushState({}, '', newUrl);
		});
	});
	


	function isFloat(value) {
		return !isNaN(value) && Number(value) === parseFloat(value);
	}


	function handleAjaxRequest() {
		if (window.location.search) {

			$('#js-filtering-home').addClass('loading-btn');
			$('.spinner-wrap').addClass('loading');
			$('.preloader').addClass('hidden');
			const params = new URLSearchParams(window.location.search);

			var locations = params.get('locations');
			var teams = params.get('teams');
			var types = params.get('types');
			var homeoffice = params.get('homeoffice');
			var min_salary = params.get('min_salary');
			var max_salary = params.get('max_salary');
			var input_search = params.get('input_search');
			var radius = params.get('distance');
			var distanceResults = params.get('distanceResults');
			var latitude = params.get('latitude');
			var longitude = params.get('longitude');
			const locationInput = document.querySelector('#locations');
			// Set values for the data-latitude and data-longitude attributes
			if (latitude && isFloat(latitude)) {
				locationInput.setAttribute('data-latitude', latitude);
			}
			if (longitude && isFloat(longitude)) {
				locationInput.setAttribute('data-longitude', longitude);
			}
			// Set the distance value to the <div>
			var targetDiv = $('.select-styled');
			if (targetDiv.length) { // ensures that the element was found
				targetDiv.html(radius/1000 + ' km');
			}
			// Add 'is-selected' class to the matching option in the select element
			$('#radius option').each(function() {
				if ($(this).val() === radius) {
					$(this).addClass('is-selected').siblings().removeClass('is-selected');
				}
			});

			// Remove 'is-selected' class from the current default item
			$('.select-options .is-selected').removeClass('is-selected');

			// Add 'is-selected' class to the matching <li> element based on the rel attribute
			$('.select-options li[rel="' + radius + '"]').addClass('is-selected');

			$('#locations').val(locations);
			$('#teams').val(teams);
			$('#types').val(types);
			$('#homeoffice').val(homeoffice);
			$("#min_salary").val(min_salary);
			$("#max_salary").val(max_salary);
			$('#input_search').val(input_search);
			$('#radius').val(radius);
			$('#exampleModal').modal('hide')
		
			const queryString = params.toString();
			const url = `/jobs/?${queryString}`;
	
			$.ajax({
				type: 'GET',
				url: ajax_url,
				dataType: 'html',
				data: {
					'action': 'filter_projects',
					'locations': locations,
					'teams': teams,
					'types': types,
					'min_salary': min_salary,
					'max_salary': max_salary,
					'input_search': input_search,
					'distance-results': distanceResults,
					'homeoffice': homeoffice,
					'radius': radius,
					'latitude': latitude, 
					'longitude': longitude, 
				},
				success: function(res) {
					
					$('.preloader').addClass('hidden');
					$('.jobListing').html(res);
					$('#results').hide();
					var numResults = $('.jobListing .result-item').length;
					$('#js-job-number').text(numResults).css('display', numResults ? 'block' : 'none');
					
					if (numResults) {
						$('#js-job-number').text(numResults).parent().css('display', 'flex');
					} else {
						$('#js-job-number').text(numResults).parent().css('display', 'none');
					}
					  
					$('.spinner-wrap').removeClass('loading').css('display', 'flex');
					
					if (types) {
						$('#js-job-type').html(types).parent().css('display', 'flex');
					} else {
						$('#js-job-type').html(types).parent().css('display', 'none');
					}
					
					if (teams) {
						$('#js-job-team').html(teams).parent().css('display', 'flex');
					} else {
						$('#js-job-team').html(teams).parent().css('display', 'none');
					}
					
					if (locations) {
						$('#js-job-location').html(locations).parent().css('display', 'flex');
					} else {
						$('#js-job-location').html(locations).parent().css('display', 'none');
					}

					if (homeoffice) {
						$('#js-job-homeoffice').html(homeoffice).parent().css('display', 'flex');
					} else {
						$('#js-job-homeoffice').html(homeoffice).parent().css('display', 'none');
					}
					
					
					if (input_search !== '') {
						$('#js-text-search').toggle(true).html(input_search).parent().css('display', 'flex');
					} else {
						$('#js-text-search').toggle(false).html(input_search).parent().css('display', 'none');
					}
					  
	
					$('#load_using_ajax').hide();
					$('.sectionJobOffers__details').show();
					$('#exampleModalFilters').modal('hide');
				},
				error: function() {
					$('.preloader').addClass('hidden');
					$('#js-filtering-home').removeClass('loading-btn');
					$('.spinner-wrap').removeClass('loading');
			
					$('.jobListing').html('<div class="productWrapper noOfferts"><div class="productCarousel"><div class="container"><div class="row"><h1 class="text-center">Leider haben wir keine passenden Angebote</h1></div></div></div></div>');
					$('#load_using_ajax').hide();
					$('.sectionJobOffers__details').show();
					var numResults = "0";
					$('#js-job-number').text('0').css('display', numResults ? 'block' : 'none');
					if (types) {
						$('#js-job-type').html(types).parent().css('display', 'flex');
					} else {
						$('#js-job-type').html(types).parent().css('display', 'none');
					}
					
					if (teams) {
						$('#js-job-team').html(teams).parent().css('display', 'flex');
					} else {
						$('#js-job-team').html(teams).parent().css('display', 'none');
					}
					
					if (locations) {
						$('#js-job-location').html(locations).parent().css('display', 'flex');
					} else {
						$('#js-job-location').html(locations).parent().css('display', 'none');
					}

					if (homeoffice) {
						$('#js-job-homeoffice').html(homeoffice).parent().css('display', 'flex');
					} else {
						$('#js-job-homeoffice').html(homeoffice).parent().css('display', 'none');
					}
					
					
					if (input_search !== '') {
						$('#js-text-search').toggle(true).html(input_search).parent().css('display', 'flex');
					} else {
						$('#js-text-search').toggle(false).html(input_search).parent().css('display', 'none');
					}
					
				}
			});
		}
	}


	$(window).on('load', function() {
		setTimeout(function() {
			if (window.location.pathname.includes('/jobs')) {
				
				$('.preloader').addClass('hidden');
				const locationInput = document.querySelector('#locations');

				if (locationInput) {
					const latitude = parseFloat(locationInput.getAttribute('data-latitude'));
					const longitude = parseFloat(locationInput.getAttribute('data-longitude'));
					
					if (!isNaN(latitude) && !isNaN(longitude)) {
						fetchAndProcessJobs(latitude, longitude).then(validTerms => {
							const validTermIds = validTerms.map(term => term.id);
							const validTermNames = validTerms.map(term => term.name);
					
							handleAjaxRequest(validTermIds, validTermNames);
						});
					} else {
						handleAjaxRequest(); 
					}
				} else {
					handleAjaxRequest(); 
				}
		
		
		
				function sanitizeInput(input) {
					// Replace special characters with their counterparts
					const specialCharacters = {
						'Ãƒâ€žÃ¢â‚¬Â¦': 'a',
						'Ãƒâ€žÃ¢â‚¬Â¡': 'c',
						'Ãƒâ€žÃ¢â€žÂ¢': 'e',
						'Ãƒâ€¦Ã¢â‚¬Å¡': 'l',
						'Ãƒâ€¦Ã¢â‚¬Å¾': 'n',
						'ÃƒÆ’Ã‚Â³': 'o',
						'Ãƒâ€¦Ã¢â‚¬Âº': 's',
						'Ãƒâ€¦Ã‚Âº': 'z',
						'Ãƒâ€¦Ã‚Â¼': 'z',
						'ÃƒÆ’Ã‚Â¤': 'a',
						'ÃƒÆ’Ã‚Â¶': 'o',
						'ÃƒÆ’Ã‚Â¼': 'u',
						'ÃƒÆ’Ã…Â¸': 'ss',
					};
				
				
					const sanitizedInput = input.replace(/[^\w\s]/gi, (match) => {
					return specialCharacters[match] || '';
					});
				
					return sanitizedInput;
				}
			
				$('#ajax-search-form-locations').change(function() {
					var value = $(this).val();
					var url = window.location.href;
					var newUrl;
			
					// Update the query parameter in the URL
					if (url.indexOf('?') !== -1) {
						newUrl = url.replace(/ajax-search-form-locations=\d+/, 'ajax-search-form-locations=' + value);
					} else {
						newUrl = url + '?ajax-search-form-locations=' + value;
					}
			
					// Update the URL
					window.history.pushState({}, '', newUrl);
				});
			}
		}, 100); // delay 100ms
	});


//My account start

	function getLatLngForTerm(termName, callback) {
		const settings = {
			isoCountries: ['de'],
			includeFilters: { city: true },
			showCountry: false,
			showZip: false,
			showStreet: false,
			showPoi: false,
			onSelected: function(geoJson) {
				if (geoJson && geoJson.geometry && geoJson.geometry.coordinates) {
					const longitude = geoJson.geometry.coordinates[0];
					const latitude = geoJson.geometry.coordinates[1];
					callback({ lat: latitude, lng: longitude });
				} else {
					callback(null);
				}
			}
		};
	
		// console.log("Checking for autocomplete...");
		const autocomplete = ym.modules.autoComplete(termName, settings);
		if (autocomplete) {
			autocomplete.search(termName);
		} else {
			console.error("Autocomplete object not created");
		}
	}
	
	const missingTerms = []; // Let's assume these are terms without coordinates
	missingTerms.forEach(term => {
		getLatLngForTerm(term.name, (coords) => {
			if (coords) {
				// Assuming you have a function named `updateTermLatLng` that takes term ID, latitude, and longitude and returns a Promise.
				updateTermLatLng(term.id, coords.lat, coords.lng)
					.then(updatedTerm => {
						console.log(`Updated term ${updatedTerm.name} with coordinates.`);
					})
					.catch(error => {
						console.error(`Error updating term ${term.name}:`, error);
					});
			} else {
				console.error(`Could not find coordinates for ${term.name}`);
			}
		});
	});
	
//My account end




	$(document).ready(function() {

		$('.modal').on('hidden.bs.modal', function() {
		  $('.modal-backdrop').remove();
		});

		$(document).on('click', function(event) {
		  if ($('.modal').hasClass('show') && !$(event.target).closest('.modal-dialog').length) {
			$('.modal').modal('hide');
		  }
		});
	  });



	$(document).ready(function() {
		$('.compare-row:even').addClass('even');

		$('input').on('focus', function() {
			$(this).addClass('hidden-placeholder');
		  });

		  $('input').on('blur', function() {
			$(this).removeClass('hidden-placeholder');
		  });

		  $('textarea').on('focus', function() {
			$(this).addClass('hidden-placeholder');
		  });

		  $('textarea').on('blur', function() {
			$(this).removeClass('hidden-placeholder');
		  });
	});

	$("#js-edit").click(function(e) {
		e.preventDefault();
		$("#edit-job").toggle();
		$("html, body").animate({scrollTop: $("#edit-job").offset().top}, "slow");
	});

	$(document).ready(function() {
	

		if($('#ajax-search-form-locations').length) {
			let placeholderText = $('#input_search').attr('placeholder');
			$('#input_search').on('focusin', function(e){
				$('#input_search').attr('placeholder', '');
				$(this).parent().parent().addClass('focus');
			}).on('focusout', function(e) {
				$(this).parent().parent().removeClass('focus');
				$('#input_search').attr('placeholder', placeholderText);
			});
		}

		if($('#ajax-search-form-locations').length) {
			let placeholderText = $('#locations').attr('placeholder');
			$('#locations').on('focusin', function(e){
				$('#locations').attr('placeholder', '');
				$('.input-border').addClass('focus');
			}).on('focusout', function(e) {
				$('.input-border').removeClass('focus');
				$('#locations').attr('placeholder', placeholderText);
			});
		}

		if ($('#ajax-search-form-locations').length) {
			// Function to toggle the 'focus' class
			function toggleFocus() {
				if ($('.select-styled').hasClass('active')) {
					$('.input-border').addClass('focus');
				} else {
					$('.input-border').removeClass('focus');
				}
			}
		
			// Call the function on page load
			toggleFocus();
		
			// Handle click on element with class 'select-styled'
			$('.select-styled').on('click', function() {
				toggleFocus();
			});
		
			// Remove 'focus' class on selecting an option from 'select'
			$('select').on('change', function() {
				$('.input-border').removeClass('focus');
			});
		
			// Remove 'focus' class on click anywhere outside '#ajax-search-form-locations'
			$(document).on('click', function(e) {
				if (!$(e.target).closest('#ajax-search-form-locations').length) {
					$('.input-border').removeClass('focus');
				}
			});
		}
		
		   
		
	
	});



	$(document).ready(function() {
		$('.list-inline-item').click(function() {
			$('.list-inline-item').removeClass('active');
			$(this).addClass('active');
		});
	});


	$( "#js-toggle-form" ).click(function(e) {
		e.preventDefault();
		$( "#js-form-container" ).toggle();
	});

	$( "#js-acf-btn" ).click(function() {
		$( ".sectionHero__caption" ).addClass('success');
	});

	$('.burger2').on('click', function () {
		if($('.burger2').hasClass('on')) {
			$(".burger2").removeClass('on');
			$("#js-mobileMenu").fadeOut( 100 );
			$(".headerWrapper").removeClass('on');
			$('html').removeClass('mobile-menu-open');
		} else {
			$(".burger2").addClass('on');
			$(".headerWrapper").addClass('on');
			$("#js-mobileMenu").fadeIn( 100 );
			$('html').addClass('mobile-menu-open');

		}
	 });


	 $('.wpcf7-submit').on('click', function () {
		if($('.wpcf7-form').hasClass('sent')) {
			$('#exampleModal').modal('hide')
		}
	 });




	 $('.form-check-input').on('click', function () {
		if($('.form-check-input').hasClass('checked')) {
			$(".form-check-input").removeClass('checked');
			this.removeAttribute("checked");
			this.checked = false;

		} else {
			$(".form-check-input").addClass('checked');
			this.setAttribute("checked", "");
		}
	 });

	


	if(password) {
		var password = document.getElementById("acf-field_6368eedbd95f2")
		, confirm_password = document.getElementById("acf-field_6388864d419bc");

		function validatePassword(){
		if(password.value != confirm_password.value) {
			confirm_password.setCustomValidity("Passwords Don't Match");
		} else {
			confirm_password.setCustomValidity('');
		}
		}

		password.onchange = validatePassword;
		confirm_password.onkeyup = validatePassword;
	}

});

(function() {
	'use strict';

	// Focus input if Searchform is empty
	[].forEach.call(document.querySelectorAll('.search-form'), (el) => {
		el.addEventListener('submit', function(e) {
			var search = el.querySelector('input');
			if (search.value.length < 1) {
				e.preventDefault();
				search.focus();
			}
		});
	});

	// Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
		return new bootstrap.Popover(popoverTriggerEl, {
			trigger: 'focus',
		});
	});
})();



jQuery(function ($) {

var percent = 30;
var window_scrolled = (percent * $(document).height()) / 100;

if ($(window).scrollTop() + $(window).height() >= window_scrolled) {

	$('.counting').each(function() {
		var $this = $(this),
			countTo = $this.attr('data-count');
		$({ countNum: $this.text()}).animate({
		countNum: countTo
		},
		{
		duration: 3000,
		easing:'linear',
		step: function() {
			$this.text(Math.floor(this.countNum));
		},
		complete: function() {
			$this.text(this.countNum);
		}
		});
	});

}




  $(document).ready(function() {
	$('#locationSearch').change(function() {
	  var inputValue = $(this).val();
	  $('#local').find('option[value="' + inputValue + '"]').prop('selected', true);
	});
  });

// Ajax loading job offers
  let currentPage2 = 0;



  	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var parms = query.split('&');
		for (var i = 0; i < parms.length; i++) {
			var pos = parms[i].indexOf('=');
			if (pos > 0 && variable == parms[i].substring(0, pos)) {
				return parms[i].substring(pos + 1);;
			}
		}
		return "";
	}



	getQueryVariable("locations");
	getQueryVariable("teams");
	getQueryVariable("types");
	getQueryVariable("min_salary");
	getQueryVariable("max_salary");
	getQueryVariable("input_search");
	getQueryVariable("radius");

	$(function () {
		$('#locations').val(getQueryVariable('locations'));
		$('#teams').val(getQueryVariable('teams'));
		$('#types').val(getQueryVariable('types'));
		$('#min_salary').val(getQueryVariable('min_salary'));
		$('#max_salary').val(getQueryVariable('max_salary'));
		$('#input_search').val(getQueryVariable('input_search'));
		$('#radius').val(getQueryVariable('radius'));
	});




  $(document).on('click','#js-filtering',function(e){
	  e.preventDefault();
	  var locations = $('#locations-select option:selected').val();
	  $('#locations').val($('#locations-select option:selected').text());

	  var teams = $('#teams').val();
	  var types = $('#types').val();
	  var min_salary = $("#min_salary").val();
	  var max_salary = $("#max_salary").val();
	  var input_search = $( '#input_search' ).val();
	  $('#exampleModalFilters').modal('hide');

	  currentPage2++;



  	const params = {
	  locations: locations,
	  teams: teams,
	  types: types,
	  min_salary: min_salary,
	  max_salary: max_salary,
	  input_search: input_search,
  	};

	// Serialize the object into a query string
	const queryString = $.param(params);

	// Add the query string to the URL
	const url = `/jobs/?${queryString}`;



	$.ajax({
		type: 'GET',
		url: ajax_url,
		dataType: 'html', 
		data: {
		'action': 'filter_projects',
		locations: locations,
		teams : teams,
		types: types,
		min_salary: min_salary,
		max_salary: max_salary,
		input_search: input_search,
		},
		success: function (res) {
			
			window.history.pushState({}, '', url);
		

			$('.jobListing').html(res);
			$('#results').hide();
			var numResults = $('.jobListing .result-item').length;

			$('#js-job-number').text(numResults);
			
			
			if (types && Array.isArray(types) && types.length >= 2) {
				$('#js-job-type').html(types);
				$('#job-type').show();
			} else {
				$('#job-type').hide();
			}

			if(teams.length >= 2) {
				$('#js-job-team').html(teams);
				$('#job-team').show();
			} else {
				$('#job-team').hide();
			}

			if(locations.length >= 2) {
				$('#js-job-location').html(locations);
				$('#job-location').show();
			} else {
				$('#job-location').hide();
			}

			if(min_salary.length >= 2) {
				$('#js-job-min-salary').html(min_salary);
				$('#min-salary').show();
			} else {
				$('#min-salary').hide();
			}

			if(max_salary.length >= 2) {
				$('#js-job-max-salary').html(max_salary);
				$('#max-salary').show();
			} else {
				$('#max-salary').hide();
			}



			if(input_search!='') {
				$('#js-text-search').html(input_search);
				$('#text-search').show();
			} else {
				$('#text-search').hide();
			}


			$('#load_using_ajax').hide();
			$('.sectionJobOffers__details').show();
			$('#exampleModalFilters').modal('hide');
		},

		error: function () {
			var numResults = $('.jobListing .result-item').length;
			$('#js-job-number').text('0');
			$('#exampleModalFilters').modal('hide');
			return;
		}
	});


	$('#ajax-search-form-locations').change(function() {
		var value = $(this).val();
		var url = window.location.href;
		var newUrl;

		// Update the query parameter in the URL
		if (url.indexOf('?') !== -1) {
		newUrl = url.replace(/ajax-search-form-locations=\d+/, 'ajax-search-form-locations=' + value);
		} else {
		newUrl = url + '?ajax-search-form-locations=' + value;
		}

		// Update the URL
		window.history.pushState({}, '', newUrl);
	});


 });

  $('#js-headSearch input').keypress(function() {


			function sanitizeInput(input) {
				// Zamiana znakÃƒÂ³w specjalnych na odpowiedniki
				const specialCharacters = {
				  'Ã„â€¦': 'a',
				  'Ã„â€¡': 'c',
				  'Ã„â„¢': 'e',
				  'Ã…â€š': 'l',
				  'Ã…â€ž': 'n',
				  'ÃƒÂ³': 'o',
				  'Ã…â€º': 's',
				  'Ã…Âº': 'z',
				  'Ã…Â¼': 'z',
				  'ÃƒÂ¤': 'a',
				  'ÃƒÂ¶': 'o',
				  'ÃƒÂ¼': 'u',
				  'ÃƒÅ¸': 'ss',
				  // Dodaj inne niemieckie znaki specjalne, ktÃƒÂ³re chcesz obsÃ…â€šuÃ…Â¼yÃ„â€¡
				};
			  
				// Zamiana znakÃƒÂ³w specjalnych na odpowiedniki
				const sanitizedInput = input.replace(/[^\w\s]/gi, (match) => {
				  return specialCharacters[match] || '';
				});
			  
				return sanitizedInput;
			  }
			  
			  var locations = $('#locations').val();
			  var teams = $('#teams').val();
			  var types = $('#types').val();
			  var min_salary = $("#min_salary").val();
			  var max_salary = $("#max_salary").val();
			  var input_search;
		
			  if ($("#input_search_head").val()) {
				input_search = escape($("#input_search_head").val());
			  } else {
				input_search = escape($("#input_search_head_mobile").val());
			  }
			
			  $('#exampleModal').modal('hide')
			
			  currentPage2++;
			
			  const params = {
				locations: locations,
				teams: teams,
				types: types,
				min_salary: min_salary,
				max_salary: max_salary,
				input_search: input_search,
			  };
			
			  const queryString = $.param(params);
			  const url = `/jobs/?${queryString}`;
		
			$.ajax({
				type: 'GET',
				url: ajax_url,
			  //   url: url,
				dataType: 'html', // <-- Change dataType from 'html' to 'json'
				data: {
				 'action': 'filter_projects',
				 locations: locations,
				 teams : teams,
				 types: types,
				 min_salary: min_salary,
				 max_salary: max_salary,
				 input_search: input_search,
				},
				success: function (res) {
				
						window.history.pushState({}, '', url);
						window.location.href = url;
		
						$('.jobListing').html(res);
		
						var numResults = $('.jobListing .result-item').length;
		
						$('#js-job-number').text(numResults);
		
						if(types.length >= 2) {
							$('#js-job-type').html(types);
							$('#job-type').show();
						} else {
							$('#job-type').hide();
						}
		
						if(teams.length >= 2) {
							$('#js-job-team').html(teams);
							$('#job-team').show();
						} else {
							$('#job-team').hide();
						}
		
						if(locations.length >= 2) {
							$('#js-job-location').html(locations);
							$('#job-location').show();
						} else {
							$('#job-location').hide();
						}
		
						if(min_salary.length >= 2) {
							$('#js-job-min-salary').html(min_salary);
							$('#min-salary').show();
						} else {
							$('#min-salary').hide();
						}
		
						if(max_salary.length >= 2) {
							$('#js-job-max-salary').html(max_salary);
							$('#max-salary').show();
						} else {
							$('#max-salary').hide();
						}
		
		
						if(input_search!='') {
							$('#js-text-search').html(input_search);
							$('#text-search').show();
						} else {
							$('#text-search').hide();
						}
		
		
						window.history.pushState({}, '', url);
						window.location.href = url;
		
					
				},
				error: function () {
					
					$('.jobListing').html('<div class="productWrapper noOfferts"><div class="productCarousel"><div class="container"><div class="row"><h1 class="text-center">Leider haben wir keine passenden Angebote</h1></div></div></div></div>');
					$('#load_using_ajax').hide();
					$('.sectionJobOffers__details').show();
					var numResults = $('.jobListing .result-item').length;
		
					$('#js-job-number').text('0');
		
					return;
				}
		
			});
		
		
			$('#ajax-search-form-locations').change(function() {
			  var value = $(this).val();
			  var url = window.location.href;
			  var newUrl;
		
			  // Update the query parameter in the URL
			  if (url.indexOf('?') !== -1) {
				newUrl = url.replace(/ajax-search-form-locations=\d+/, 'ajax-search-form-locations=' + value);
			  } else {
				newUrl = url + '?ajax-search-form-locations=' + value;
			  }
		
			  // Update the URL
			  window.history.pushState({}, '', newUrl);
			});
	
		
	
  });



(function( $ ) {

	/**
	 * initMap
	 *
	 * Renders a Google Map onto the selected jQuery element
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   jQuery $el The jQuery element.
	 * @return  object The map instance.
	 */
	function initMap( $el ) {

		// Find marker elements within map.
		var $markers = $el.find('.marker');

		// Create gerenic map.
		var mapArgs = {
			zoom        : $el.data('zoom') || 16,
			mapTypeId   : google.maps.MapTypeId.ROADMAP
		};
		var map = new google.maps.Map( $el[0], mapArgs );

		// Add markers.
		map.markers = [];
		$markers.each(function(){
			initMarker( $(this), map );
		});

		// Center map based on markers.
		centerMap( map );

		// Return map instance.
		return map;
	}

	/**
	 * initMarker
	 *
	 * Creates a marker for the given jQuery element and map.
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   jQuery $el The jQuery element.
	 * @param   object The map instance.
	 * @return  object The marker instance.
	 */
	function initMarker( $marker, map ) {

		// Get position from marker.
		var lat = $marker.data('lat');
		var lng = $marker.data('lng');
		var latLng = {
			lat: parseFloat( lat ),
			lng: parseFloat( lng )
		};

		// Create marker instance.
		var marker = new google.maps.Marker({
			position : latLng,
			map: map
		});

		// Append to reference for later use.
		map.markers.push( marker );

		// If marker contains HTML, add it to an infoWindow.
		if( $marker.html() ){

			// Create info window.
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html()
			});

			// Show info window when marker is clicked.
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );
			});
		}
	}

	/**
	 * centerMap
	 *
	 * Centers the map showing all markers in view.
	 *
	 * @date    22/10/19
	 * @since   5.8.6
	 *
	 * @param   object The map instance.
	 * @return  void
	 */
	function centerMap( map ) {

		// Create map boundaries from all map markers.
		var bounds = new google.maps.LatLngBounds();
		map.markers.forEach(function( marker ){
			bounds.extend({
				lat: marker.position.lat(),
				lng: marker.position.lng()
			});
		});

		// Case: Single marker.
		if( map.markers.length == 1 ){
			map.setCenter( bounds.getCenter() );

		// Case: Multiple markers.
		} else{
			map.fitBounds( bounds );
		}
	}

	// Render maps on page load.
	$(document).ready(function(){
		$('.acf-map').each(function(){
			var map = initMap( $(this) );
		});
	});

	$("input#keyword, input#locations").keyup(function() {
		if ($(this).val().length > 2) {
		  $("#datafetch").show();
		} else {
		  $("#datafetch").hide();
		}
	});


})(jQuery);



	window.onload=function(){
		// Get references to the select elements
		const select1 = document.getElementById('locations-main');
		const select2 = document.getElementById('locations');

		if($('#locations-main').length) {
			// Add an event listener for changes to the first select element
			select1.addEventListener('change', function() {
				// Get the value of the first select element
				const value = this.value;

				// Set the value of the second select element to match the first one
				select2.value = value;
			});
		}
	}


	$(document).ready(function() {
		// Existing sticky header code
		$(document).scroll(function(e) {
			var scrollTop = $(document).scrollTop();
			if (scrollTop > 75) {
				$('.headerWrapper').addClass('sticky');
				$('body').addClass('stickyBody');
			} else {
				$('.headerWrapper').removeClass('sticky');
				$('body').removeClass('stickyBody');
			}
		});

		if ($('.js-sticky-widget').length) {
			var width = $("body").width();
			var stickyEl;

			if (width > 1200) {
				stickyEl = new Sticksy('.js-sticky-widget', {
					topSpacing: 40,
					delay: 200, // delay in ms
					duration: 500 // duration in ms
				});

				stickyEl.onStateChanged = function(state) {
					if (state === 'fixed') {
						stickyEl.nodeRef.classList.add('widget--sticky');
					} else {
						stickyEl.nodeRef.classList.remove('widget--sticky');
					}
				};
			}

			// Event listener for all buttons and anchor tags
			$('button, a, #edit-details').on('click', function() {
				// Ensure stickyEl is initialized and is a Sticksy instance
				if (stickyEl && stickyEl instanceof Sticksy) {
					setTimeout(function() {
						stickyEl.hardRefresh();
					}, 300); // Adjust timeout based on your needs
				}
			});
		}
	});

});




	(function($){
		var search_button = $('.search__icon'),
			close_button  = $('.close'),
			input = $('.input');
		search_button.on('click',function(){
		$(this).parent().addClass('open');
		close_button.fadeIn(500);
		input.fadeIn(500);
		});

		close_button.on('click',function(){
		search_button.parent().removeClass('open');
		close_button.fadeOut(500);
		input.fadeOut(500);
		});
	})(jQuery);


	function human_time_diff(datetime) {
		var datetime = typeof datetime !== 'undefined' ? datetime : "2014-01-01 01:02:03.123456";

		var datetime = new Date(datetime).getTime();
		var now = new Date().getTime();

		if (isNaN(datetime)) {
			return "";
		}

		// allow for slight difference in time, due to latency
		if (datetime < now) {
			var milisec_diff = now - datetime;
		} else {
			var milisec_diff = datetime - now;
		}

		var seconds = milisec_diff / 1000;
		var minutes = seconds / 60;
		var hours = minutes / 60;
		var days = hours / 24;
		let language = document.documentElement.lang; 
		if (language === "de-DE") {
			if (days > 1) {
				return "Vor " + Math.round(days) + " Tagen";
			} else if (hours > 1) {
				return "Vor " + Math.round(hours) + " Stunden";
			} else if (minutes > 1) {
				return "Vor " + Math.round(minutes) + " ein paar Minuten";
			} else {
				return "soeben";
			}


		} else {
			if (days > 1) {
				return "Vor " + Math.round(days) + " Tagen";
			} else if (hours > 1) {
				return "Vor " + Math.round(hours) + " Stunden";
			} else if (minutes > 1) {
				return "Vor " + Math.round(minutes) + " ein paar Minuten";
			} else {
				return "soeben";
			}
		}

	}

	function sliderWithTitleAndTextAndButtonSwiper(slider, index) {
		let sliderClass = 'js-sectionSliderWithTitleAndTextAndButton-'+index;
		slider.addClass(sliderClass);

		let swiperContainer 	= '.'+sliderClass +' .swiper-container';
		let swiperPagination 	= '.'+sliderClass +' .swiper-pagination';
		let swiperPrevBtn		= '.'+sliderClass +' .swiper-button-prev';
		let swiperNextBtn		= '.'+sliderClass +' .swiper-button-next';

		var swiper = new Swiper(swiperContainer, {
			slidesPerView: 1,
			cssWidthAndHeight: true,
			loop: true,
			navigation: {
				nextEl: swiperNextBtn,
				prevEl: swiperPrevBtn,
			},

			pagination: {
				el: swiperPagination,
			},

			breakpoints: {
				768: {
					slidesPerView: 1,
				},
				1025: {
					slidesPerView: 1,
					pagination: {
						el: swiperPagination,
						type: "fraction",
						formatFractionCurrent: function(number) {
							return ('0' + number).slice(-2);
						},
						formatFractionTotal: function(number) {
							return ('0' + number).slice(-2);
						},
						renderFraction: function(currentClass, totalClass) {
							return '<span class="' + currentClass + '"></span>' +
								'/' +
								'<span class="' + totalClass + '"></span>';
						}
					},
				}
			}

		});
	}

	function createSliderCategoriesBannersSwiper(slider, pagination) {
		var swiper = new Swiper(slider, {
			slidesPerView: 1,
			cssWidthAndHeight: true,
			loop: true,
			pagination: {
				enabled: true,
				el: pagination,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1200: {
					slidesPerView: 4,
					loop: false,
				}
			}
		});
	}

	function createIconBoxesSwiper(slider, pagination) {
		var swiper = new Swiper(slider, {
			slidesPerView: 1,
			cssWidthAndHeight: true,
			loop: true,
			spaceBetween: 50,
			pagination: {
				el: pagination,
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
				},
				1200: {
					spaceBetween: 0,
					slidesPerView: 3,
					loop: false,
				}
			}
		});
	}


	function sliderCategoriesBannersSwiper(slider, index) {
		let sliderClass = 'js-sectionCategoriesBanners-'+index;
		slider.addClass(sliderClass);
		$('.'+sliderClass +' .js-swiper-container').addClass('swiper-container');
		$('.'+sliderClass +' .js-swiper-wrapper').addClass('swiper-wrapper');
		$('.'+sliderClass +' .js-swiper-slide').addClass('swiper-slide');
		let swiperContainer 	= '.'+sliderClass +' .swiper-container';
		let swiperPagination 	= '.'+sliderClass +' .swiper-pagination';
		createSliderCategoriesBannersSwiper(swiperContainer, swiperPagination);
	}

	function sliderIconBoxesSwiper(slider, index) {
		let sliderClass = 'js-iconBoxes-'+index;
		slider.addClass(sliderClass);
		let swiperContainer 	= '.'+sliderClass +' .swiper-container';
		let swiperPagination 	= '.'+sliderClass +' .swiper-pagination';
		$('.'+sliderClass +' .js-swiper-container').addClass('swiper-container');
		$('.'+sliderClass +' .js-swiper-wrapper').addClass('swiper-wrapper');
		$('.'+sliderClass +' .js-swiper-slide').addClass('swiper-slide');
		createIconBoxesSwiper(swiperContainer, swiperPagination);
	}

	let sliderWithTitleAndTextAndButton = $('.js-sectionSliderWithTitleAndTextAndButton');
	if(sliderWithTitleAndTextAndButton.length) {
		sliderWithTitleAndTextAndButton.each(function(e){
			sliderWithTitleAndTextAndButtonSwiper($(this), e);
		});
	}

	let sliderCategoriesBanners = $('.js-sectionCategoriesBanners');

	if(sliderCategoriesBanners.length) {
		sliderCategoriesBanners.each(function(e){

			sliderCategoriesBannersSwiper($(this), e);
		});
	}


	let sliderIconBoxes = $('.js-iconBoxes');
	if(sliderIconBoxes.length) {
		sliderIconBoxes.each(function(e){
			sliderIconBoxesSwiper($(this), e);
		});
	}


	$('.sectionFaq__faq-single').on('click', function(e) {
		if($(this).find('.sectionFaq__faq-question.active').length) {

		} else {
			$('.sectionFaq__faq-question.active').next().slideUp();
			$('.sectionFaq__faq-question.active').removeClass('active');
		}
		$(this).find('.sectionFaq__faq-question').toggleClass('active');
		$(this).find('.sectionFaq__faq-answer').slideToggle();
	});



	$('.sectionApp__app-single').on('click', function(e) {
		// Check if the click is on the btn-applicants button
		if ($(e.target).hasClass('btn-applicants') && !$(e.target).hasClass('disabled')) {
			// Find the .sectionApp__app-answer section inside the current .sectionApp__app-single
			var answerSection = $(this).find('.sectionApp__app-answer');
	
			// Toggle the 'active' class on the btn-applicants button if present
			$(e.target).toggleClass('active');
	
			// If btn-applicants button has the 'active' class, open or close the .sectionApp__app-answer section
			if ($(e.target).hasClass('active')) {
				answerSection.slideDown();
	
				// Add 'active' class to .sectionApp__app-single
				$(this).addClass('active');
			} else {
				answerSection.slideUp();
	
				// Remove 'active' class from .sectionApp__app-single
				$(this).removeClass('active');
			}
		} else if (!$(e.target).hasClass('btn-applicants')) {
			// If the click is not on the btn-applicants button, treat it as a click on .sectionApp__app-single
			var btnApplicantsActive = $(this).find('.btn-applicants.active').length;
			var btnApplicantsDisabled = $(this).find('.btn-applicants.disabled').length;
	
			if (!btnApplicantsActive && !btnApplicantsDisabled) {
				// Do not add 'active' class to .sectionApp__app-single
				$(this).find('.sectionApp__app-answer').slideUp();
			}
		}
	});
	
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll >= 600) {
			$("#anim").addClass("animation");
		}
	});




	$(document).ready(function() {
		$("#your-name").on("focus", function() {
		$("label[for='your-name']").addClass("moved");
		}).on("blur", function() {
		if ($(this).val() === "") {
			$("label[for='your-name']").removeClass("moved");
		}
		});

		$("#your-email").on("focus", function() {
			$("label[for='your-email']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-email']").removeClass("moved");
			}
		});

		$("#your-message").on("focus", function() {
			$("label[for='your-message']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-message']").removeClass("moved");
			}
		});

		$("#your-addresse").on("focus", function() {
			$("label[for='your-addresse']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-addresse']").removeClass("moved");
			}
		});


		$("#tel-437").on("focus", function() {
			$("label[for='tel-437']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='tel-437']").removeClass("moved");
			}
		});

		$("#your-name-opinion").on("focus", function() {
			$("label[for='your-name-opinion']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-name-opinion']").removeClass("moved");
			}
		});

		$("#your-message-opinion").on("focus", function() {
			$("label[for='your-message-opinion']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-message-opinion']").removeClass("moved");
			}
		});

		$("#your-name-opinion").on("focus", function() {
			$("label[for='your-name-opinion']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-name-opinion']").removeClass("moved");
			}
		});

		$("#your-name-direct").on("focus", function() {
			$("label[for='your-name-direct']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-name-direct']").removeClass("moved");
			}
		});

		$("#your-email-direct").on("focus", function() {
			$("label[for='your-email-direct']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-email-direct']").removeClass("moved");
			}
		});

		$("#your-subject-direct").on("focus", function() {
			$("label[for='your-subject-direct']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-subject-direct']").removeClass("moved");
			}
		});

		$("#your-message-direct").on("focus", function() {
			$("label[for='your-message-direct']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-message-direct']").removeClass("moved");
			}
		});

		$("#your-name-contact").on("focus", function() {
			$("label[for='your-name-contact']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-name-contact']").removeClass("moved");
			}
		});

		$("#your-email-contact").on("focus", function() {
			$("label[for='your-email-contact']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-email-contact']").removeClass("moved");
			}
		});

		$("#your-message-contact").on("focus", function() {
			$("label[for='your-message-contact']").addClass("moved");
		}).on("blur", function() {
			if ($(this).val() === "") {
			$("label[for='your-message-contact']").removeClass("moved");
			}
		});
	});



	function closeAllSelect(elmnt) {
	/* A function that will close all select boxes in the document,
	except the current select box: */
	var x, y, i, xl, yl, arrNo = [];
	x = document.getElementsByClassName("select-items");
	y = document.getElementsByClassName("select-selected");
	xl = x.length;
	yl = y.length;
	for (i = 0; i < yl; i++) {
		if (elmnt == y[i]) {
		arrNo.push(i)
		} else {
		y[i].classList.remove("select-arrow-active");
		}
	}
	for (i = 0; i < xl; i++) {
		if (arrNo.indexOf(i)) {
		x[i].classList.add("select-hide");
		}
	}
	}

	/* If the user clicks anywhere outside the select box,
	then close all select boxes: */
	document.addEventListener("click", closeAllSelect);




$(document).ready(function(){

	var $searchTrigger = $('[data-ic-class="search-trigger"]'),
		$searchInput = $('[data-ic-class="search-input"]'),
		$searchClear = $('[data-ic-class="search-clear"]');

		$searchTrigger.click(function(){
			var $this = $('[data-ic-class="search-trigger"]');
			$this.addClass('active');
			$searchInput.focus();
		});

		$searchInput.blur(function(){
			if($searchInput.val().length > 0){
				return false;
			} else {
				$searchTrigger.removeClass('active');
			}
		});

		$searchClear.click(function(){
			$searchInput.val('');
		});

		$searchInput.focus(function(){
			$searchTrigger.addClass('active');
		});

	});

	$('.acf-field-64eddb5a45c64 .acf-checkbox-list input[type="checkbox"]').on('change', function() {
		if ($(this).prop('checked')) {
			$(this).parent().siblings().find('input[type="checkbox"]').prop('checked', false);
		}
	});


  	if (window.location.pathname === '/preisliste') {

		document.getElementById("basic-a").classList.add("active");
		document.getElementById("basic").classList.add("active");
		document.querySelectorAll(".single-package").forEach(function(packageElement) {
		if (packageElement.id !== "basic") {
			packageElement.style.display = "none";
		}
		});
  
		document.querySelectorAll("#package-list li").forEach(function(item) {
		item.addEventListener("click", function(event) {
			event.preventDefault();
	
			document.querySelectorAll("#package-list li").forEach(function(listItem) {
			listItem.classList.remove("active");
			});
	
			document.querySelectorAll(".single-package").forEach(function(packageElement) {
			packageElement.classList.remove("active");
			packageElement.style.display = "none";
			});
	
			item.classList.add("active");
	
			var packageId = item.querySelector("a").getAttribute("href").substring(1);
	
			document.getElementById(packageId).classList.add("active");
			document.getElementById(packageId).style.display = "block";
		});
		});
 	}
  


	 function addPlaceholdersFromLabels() {
		// Find all label elements within the div with class "woocommerce-billing-fields"
		const labels = document.querySelectorAll('.woocommerce-billing-fields label');
	
		// Iterate through each label
		labels.forEach((label) => {
			// Get the text from the label
			const labelText = label.innerText;
	
			// Find the corresponding input using the "for" attribute in the label
			const inputId = label.getAttribute('for');
			const input = document.getElementById(inputId);
	
			// If the input is found, add a placeholder attribute to it with the text from the label
			if (input) {
				input.placeholder = labelText;
			}
		});
	}
	
	// Call the function when the page is loaded
	window.addEventListener('load', addPlaceholdersFromLabels);
	

	// jQuery(document).ready(function($) {
	// 	$('#submitJob').on('submit', function(e) {
	// 		var $form = $(this);

	// 		alert('ok');
	
	// 		// Pobierz wartości z formularza
	// 		var $standortInput = $('#acf-field_66ab92894fc56');
	// 		var term_name = $standortInput.val();
	// 		var latitude = $standortInput.data('latitude');
	// 		var longitude = $standortInput.data('longitude');
	// 		var land = $('#land').val(); // Załóżmy, że pole 'land' ma ID 'land'
	
	// 		if (!term_name || !latitude || !longitude || !land) {
	// 			alert('Proszę uzupełnić wszystkie wymagane pola.');
	// 			return false;
	// 		}
	
	// 		// Sprawdź, czy term już istnieje
	// 		return $.ajax({
	// 			url: ajax_object.ajax_url,
	// 			method: 'POST',
	// 			data: {
	// 				term_name: term_name,
	// 				latitude: latitude,
	// 				longitude: longitude,
	// 				land: land
	// 			},
	// 			success: function(response) {
	// 				if (response.message === 'Nie znaleziono termu') {
	// 					// Term nie istnieje, dodaj nowy
	// 					$.ajax({
	// 						url: ajax_object.ajax_url,
	// 						method: 'POST',
	// 						data: {
	// 							term_name: term_name,
	// 							latitude: latitude,
	// 							longitude: longitude,
	// 							land: land
	// 						},
	// 						success: function(response) {
	// 							if (response.message === 'Pomyślnie zaktualizowano lokalizację') {
	// 								alert('Nowa lokalizacja została dodana i zaktualizowana.');
	// 								$form.off('submit').submit();
	// 							} else {
	// 								alert('Wystąpił błąd podczas dodawania nowej lokalizacji.');
	// 							}
	// 						},
	// 						error: function() {
	// 							alert('Wystąpił błąd podczas komunikacji z serwerem.');
	// 						}
	// 					});
	// 				} else {
	// 					// Term istnieje, kontynuuj przesyłanie formularza
	// 					$form.off('submit').submit();
	// 				}
	// 			},
	// 			error: function() {
	// 				alert('Wystąpił błąd podczas komunikacji z serwerem.');
	// 			}
	// 		}).then(function() {
	// 			return false;
	// 		});
	// 	});
	// });
	
	// jQuery(document).ready(function($) {
	// 	// Function to handle the change event
	// 	function handleInputChange() {
	// 		var inputField = $('#acf-field_66ab92894fc56');
	// 		console.log('Input Field:', inputField);
	
	// 		// We assume the div containing the location text is immediately after the input field
	// 		var locationDiv = inputField.next('div');
	// 		if (locationDiv.length === 0) {
	// 			console.error('Location Div not found');
	// 		} else {
	// 			console.log('Location Div:', locationDiv);
	// 		}
	
	// 		var locationText = locationDiv.text().trim();
	// 		console.log('Location Text:', locationText);
	
	// 		var latitude = inputField.attr('data-latitude');
	// 		var longitude = inputField.attr('data-longitude');
	// 		console.log('Latitude:', latitude);
	// 		console.log('Longitude:', longitude);
	
	// 		var locationParts = locationText.split(', ');
	// 		var city = locationParts[0] ? locationParts[0].trim() : '';
	// 		var country = locationParts[1] ? locationParts[1].trim() : '';
	// 		console.log('City:', city);
	// 		console.log('Country:', country);
	
	// 		if (!city || !country) {
	// 			console.error('Error: City or country data missing.');
	// 			alert('Error: City or country data missing.');
	// 			return null;
	// 		}
	
	// 		return {
	// 			city: city,
	// 			country: country,
	// 			latitude: latitude,
	// 			longitude: longitude
	// 		};
	// 	}
	
	// 	// Click event for the submit button
	// 	$('#submit-job-btn').click(function() {
	// 		var locationData = handleInputChange();
	
	// 		if (!locationData) return;
	
	// 		$.ajax({
	// 			url: ajaxurl, // WordPress ajaxurl
	// 			type: 'POST',
	// 			data: {
	// 				action: 'process_location_data',
	// 				city: locationData.city,
	// 				country: locationData.country,
	// 				latitude: locationData.latitude,
	// 				longitude: locationData.longitude
	// 			},
	// 			success: function(response) {
	// 				alert(response);
	// 			},
	// 			error: function(xhr, status, error) {
	// 				console.error('AJAX Error:', error);
	// 				alert('AJAX Error: ' + error);
	// 			}
	// 		});
	// 	});
	
	// 	// Input event for the acf-field_66ab92894fc56 input field
	// 	$('#acf-field_66ab92894fc56').on('input', function() {
	// 		var inputField = $(this);
	// 		var inputValue = inputField.val();
	
	// 		if (inputValue.length >= 3) {
	// 			handleInputChange();
	// 		}
	// 	});
	
	// 	// Change event to capture selection changes in the autocomplete input
	// 	$('#acf-field_66ab92894fc56').on('change', function() {
	// 		handleInputChange();
	// 	});
	// });
	
	// jQuery(document).ready(function($) {
	// 	function logInputValue() {
	// 		var inputField = $('#acf-field_66ab92894fc56');
	// 		var inputValue = inputField.val().trim();
	// 		console.log('Input Value:', inputValue);
	// 		var latitude = inputField.attr('data-latitude');
	// 		var longitude = inputField.attr('data-longitude');
	// 		console.log('Latitude:', latitude);
	// 		console.log('Longitude:', longitude);
	
	// 		checkAndAddTerm(inputValue);
	// 	}
	
	// 	function checkAndAddTerm(term) {
	// 		$.ajax({
	// 			url: '/wp-json/wp/v2/standort?search=' + encodeURIComponent(term),
	// 			method: 'GET',
	// 			success: function(response) {
	// 				if (response.length > 0) {
	// 					console.log('Term exists:', term);
	// 				} else {
	// 					console.log('Term does not exist. Adding:', term);
	// 					addTerm(term);
	// 				}
	// 			},
	// 			error: function(xhr, status, error) {
	// 				console.error('Error checking term:', status, error);
	// 			}
	// 		});
	// 	}
	
	// 	function addTerm(term) {
	// 		$.ajax({
	// 			url: '/wp-json/wp/v2/standort',
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				'Authorization': 'Bearer ' + yourToken // Upewnij się, że masz prawidłowy token autoryzacji
	// 			},
	// 			data: JSON.stringify({
	// 				name: term,
	// 				taxonomy: 'standort'
	// 			}),
	// 			success: function(response) {
	// 				console.log('Term added successfully:', response);
	// 			},
	// 			error: function(xhr, status, error) {
	// 				console.error('Error adding term:', status, error);
	// 			}
	// 		});
	// 	}
	
	// 	$('#submit-job-btn').click(function() {
	// 		logInputValue();
	// 	});
	
	// 	$('#acf-field_66ab92894fc56').on('input change', function() {
	// 		logInputValue();
	// 	});
	// });
	

// 	jQuery(document).ready(function($) {
// 		// Nasłuchaj na zmianę opcji w Select2
// 		$('#acf-field_648b0da01234f').on('select2:open', function() {
// 			// Oczekiwanie na załadowanie opcji
// 			setTimeout(function() {
// 				$('#select2-acf-field_648b0da01234f-results li').each(function() {
// 					var $option = $(this);
// 					var termName = $option.text();
					
// 					// Pobierz dodatkowe dane dla opcji
// 					// Tu zakładamy, że wartość `land` jest dostępna poprzez data attribute lub inny sposób
// 					var land = $option.data('land');
					
// 					// Zaktualizuj tekst opcji
// 					if (land) {
// 						$option.html('<span class="acf-selection">' + termName + ', ' + land + '</span>');
// 					}
// 				});
// 			}, 100); // Daj trochę czasu na załadowanie opcji
// 		});
// 	});

// 	jQuery(document).ready(function($) {
//     $('#acf-field_648b0da01234f').select2({
//         ajax: {
//             url: ajaxurl, // Wbudowane URL WordPress dla AJAX
//             dataType: 'json',
//             delay: 250,
//             data: function(params) {
//                 return {
//                     action: 'my_acf_ajax', // Nasza akcja AJAX
//                     q: params.term // Szukana fraza
//                 };
//             },
//             processResults: function(data) {
//                 return {
//                     results: data.data
//                 };
//             },
//             cache: true
//         }
//     });
// });

document.addEventListener('DOMContentLoaded', function() {
    // Funkcja do pobierania parametrów z fragmentu URL
    function getParameterFromHash(name) {
        const hash = window.location.hash.substring(1); // Usuń #
        const regex = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)');
        const results = regex.exec(hash);
        const value = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        
        console.log(`URL parameter '${name}':`, value); // Logowanie wartości parametru
        return value;
    }

    // Funkcja do ustawiania wartości w polach formularza
    function setFieldValue(fieldSelector, value) {
        const field = document.querySelector(fieldSelector);
        if (field) {
            field.value = value;
            field.dispatchEvent(new Event('change')); // Wywołaj zdarzenie zmiany
        }
    }

    // Funkcja do aktualizacji wartości na podstawie fragmentu URL
    function updateFieldsFromHash() {
        console.log('Current URL:', window.location.href); // Logowanie pełnego URL
        const latitude = getParameterFromHash('latitude');
        const longitude = getParameterFromHash('longitude');
        
        console.log('Latitude from URL:', latitude);
        console.log('Longitude from URL:', longitude);

        setFieldValue('#acf-field_66acc97176952', latitude);
        setFieldValue('#acf-field_66acc97e76953', longitude);
    }

    // Nasłuchuj zmian w polu formularza #acf-field_66ac9d0d692ee
    const targetField = document.querySelector('#acf-field_66ac9d0d692ee');
    if (targetField) {
        targetField.addEventListener('change', function() {
            console.log('Field #acf-field_66ac9d0d692ee changed');
            updateFieldsFromHash();
        });
    }

    // Ustaw wartości na początku, jeśli formularz jest już wypełniony
    updateFieldsFromHash();

    // Monitoruj zmiany URL
    window.addEventListener('popstate', function() {
        console.log('URL changed via popstate');
        updateFieldsFromHash();
    });

    window.addEventListener('hashchange', function() {
        console.log('URL changed via hashchange');
        updateFieldsFromHash();
    });
});